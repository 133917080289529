import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItemButton, Skeleton, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { AppScreenInfo } from '~/components/AppScreenInfo/AppScreenInfo';
import { AppointmentSummary } from '~/components/AppointmentSummary/AppointmentSummary';
import { useTerminsScreenQuery } from '~/graphql/generated';

export const Appointments: FC = () => {
  const navigate = useNavigate();
  const [now] = useState(new Date());
  const [openActiveAppointments, setOpenActiveAppointments] = useState(true);
  const [openPastAppointments, setOpenPastAppointments] = useState(true);

  const response = useTerminsScreenQuery({
    variables: {
      date: now,
    },
  });
  const hasFutureAppointments = response.data?.futureAppointments.length;
  const hasPastAppointments = response.data?.pastAppointments.length;

  if (response.loading) {
    return (
      <Stack padding={2} gap={1}>
        <Skeleton variant="text" width={70} height={40} />
        <Skeleton variant="rounded" width={'100%'} height={60} />
        <Skeleton variant="text" width={70} height={40} />
        <Skeleton variant="rounded" width={'100%'} height={60} />
      </Stack>
    );
  }

  return (
    <Stack>
      <ListItemButton
        onClick={() => setOpenActiveAppointments((prev) => !prev)}
        style={{ padding: 0, justifyContent: 'space-between' }}
      >
        <AppLabel text="nadolazeći termini" />
        {openActiveAppointments ? (
          <ExpandLess
            sx={{
              marginRight: 2,
            }}
          />
        ) : (
          <ExpandMore
            sx={{
              marginRight: 2,
            }}
          />
        )}
      </ListItemButton>
      {!hasFutureAppointments ? (
        <AppScreenInfo text="Nemate rezerviranih termina" />
      ) : (
        <Collapse in={openActiveAppointments} unmountOnExit>
          <Stack gap={0.625}>
            {response.data?.futureAppointments.map((appointment) => (
              <AppointmentSummary
                key={`${appointment.id}-future-appointment`}
                button={{
                  text: 'Pregled',
                  props: {
                    variant: 'outlined',
                    onClick: () => {
                      navigate(`${appointment.id}`);
                    },
                  },
                }}
                start={appointment.start}
                end={appointment.end}
                serviceName={appointment.service.name}
                duration={appointment.service.duration}
                price={appointment.service.price}
                barberFullName={appointment.barber.name}
                serviceDescription={appointment.service.description}
              />
            ))}
          </Stack>
        </Collapse>
      )}

      <ListItemButton
        onClick={() => setOpenPastAppointments((prev) => !prev)}
        style={{ padding: 0, justifyContent: 'space-between' }}
      >
        <AppLabel text="prethodni termini" />
        {openPastAppointments ? (
          <ExpandLess
            sx={{
              marginRight: 2,
            }}
          />
        ) : (
          <ExpandMore
            sx={{
              marginRight: 2,
            }}
          />
        )}
      </ListItemButton>
      {!hasPastAppointments ? (
        <AppScreenInfo text="Nemate termina" />
      ) : (
        <Collapse in={openPastAppointments} unmountOnExit>
          <Stack gap={0.625}>
            {response.data?.pastAppointments.map((appointment) => (
              <AppointmentSummary
                disabled
                key={`${appointment.id}-appointment`}
                button={{
                  text: 'Pregled',
                  props: {
                    variant: 'outlined',
                  },
                }}
                start={appointment.start}
                end={appointment.end}
                serviceName={appointment.service.name}
                duration={appointment.service.duration}
                price={appointment.service.price}
                barberFullName={appointment.barber.name}
              />
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};
