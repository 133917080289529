import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppHeader } from '~/components/AppHeader/AppHeader';

export default function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      <AppHeader onClick={() => navigate(-1)} />
      <Stack flex={1} height="100%" gap={2} padding={2}>
        <Typography variant="h4">Uvjeti korištenja</Typography>
        <Typography variant="body1">
          Dobrodošli na web stranicu frizerskog salona Zlatni češalj u vlasništvu Mišel Mošnja, Jurja Dobrile 10, 52352,
          Kanfanar, e-mail: zlatnicesalj@yahoo.com, telefonski broj: +385911140986. Ova politika korištenja detaljno
          opisuje uvjete i pravila koja se primjenjuju na korištenje naše web stranice. Molimo Vas da pažljivo pročitate
          ova pravila i uvjete, kao i našu Politiku privatnosti prije korištenja naše web stranice.
        </Typography>
        <Typography>KORIŠTENJE WEB STRANICE</Typography>
        <Typography>
          Korištenjem web stranice frizerskog salona Zlatni češalj, prihvaćate sve uvjete i pravila navedene u ovoj
          politici korištenja. Nije dopušteno koristiti web stranicu u nezakonite svrhe, ili na bilo koji način koji bi
          mogao ometati normalno funkcioniranje web stranice.
        </Typography>
        <Typography>USLUGE</Typography>
        <Typography>
          Naša web stranica omogućuje klijentima da rezerviraju termin za frizerske usluge koje nudimo. Termin se
          naplaćuje nakon pružene usluge. Za više informacija, molimo pogledajte našu politiku otkazivanja termina.
        </Typography>
        <Typography>INTELEKTUALNO VLASNIŠTVO</Typography>
        <Typography>
          Sva imena i logotipi frizerskog salona Zlatni češalj i drugi materijali na web stranici su zaštićeni zakonom o
          intelektualnom vlasništvu i ne smiju se koristiti bez izričitog dopuštenja frizerskog salona.
        </Typography>
        <Typography>ODGOVORNOST</Typography>
        <Typography>
          Frizerski salon se trudi da web stranica bude dostupna 24 sata dnevno, 7 dana u tjednu, međutim, ne možemo
          jamčiti da će web stranica uvijek biti dostupna. Nećemo biti odgovorni za bilo kakvu štetu koja proizlazi iz
          korištenja web stranice ili nemogućnosti korištenja web stranice.
        </Typography>
        <Typography>PROMJENE POLITIKE KORIŠTENJA</Typography>
        <Typography>
          Frizerski salon Zlatni češalj zadržava pravo izmjene ove politike korištenja u bilo kojem trenutku.
          Obavijestit ćemo Vas o bilo kojim izmjenama objavljivanjem ažuriranog teksta na našoj web stranici. Vaša
          daljnja upotreba web stranice nakon objavljivanja ažurirane politike korištenja smatrat će se Vašim
          prihvaćanjem izmjena.
        </Typography>
        <Typography>KONTAKT</Typography>
        <Typography>
          Ako imate bilo kakvih pitanja ili nedoumica u vezi s ovom politikom korištenja ili korištenjem naše web
          stranice, molimo Vas da nas kontaktirate putem e-maila na zlatnicesalj@yahoo.com ili telefona na +385 91 114
          0986 navedenih na našoj web stranici.
        </Typography>
        <hr style={{ width: '100%' }}></hr>
        <Typography variant="h5">Otkazivanje termina</Typography>
        <Typography>POLITIKA OTKAZIVANJA TERMINA</Typography>
        <Typography>
          Frizerski salon Zlatni češalj, vlasnik Mišel Mošnja, Jurja Dobrile 10, 52352, Kanfanar Cijenimo Vaše vrijeme i
          trudimo se pružiti Vam najbolju moguću uslugu. Kako bismo osigurali da svi naši klijenti dobiju potrebnu
          pažnju, molimo Vas da obavijestite salon što prije ukoliko morate otkazati svoj termin.
        </Typography>
        <Typography>OTKAZIVANJE TERMINA</Typography>
        <Typography>
          Ukoliko ste spriječeni doći na svoj termin, molimo Vas da nam to što prije javite putem telefona na +385 91
          114 0986 ili otkažete termin putem web aplikacije.
        </Typography>
        <Typography>ZAKASNJELI DOLAZAK</Typography>
        <Typography>
          Ako kasnite na svoj termin, molimo Vas da nas obavijestite što prije. U slučaju kašnjenja od više od 15
          minuta, frizerski salon Zlatni češalj zadržava pravo otkazati Vaš termin i naplatiti Vam iznos od 100%
          rezervirane usluge.
        </Typography>
        <Typography>NAPLATA USLUGE</Typography>
        <Typography>
          U slučaju da ne dođete na svoj rezervirani termin, naplatit ćemo Vam iznos od 100% rezervirane usluge.
        </Typography>
        <Typography>PRIHVAĆANJE POLITIKE OTKAZIVANJA TERMINA</Typography>
        <Typography>
          Korištenjem naših usluga i potvrđivanjem rezervacije termina, prihvaćate našu politiku otkazivanja termina.
          Molimo Vas da nas obavijestite ako imate bilo kakvih pitanja ili nedoumica. Hvala Vam na razumijevanju i na
          povjerenju koje nam ukazujete!
        </Typography>
        <hr style={{ width: '100%' }}></hr>
        <Typography variant="h5">Privatnost i korištenje korisničkih podataka</Typography>
        <Typography>
          Frizerski salon Zlatni češalj štiti Vaše osobne podatke i poštuje Vašu privatnost. Kada koristite naše usluge
          i rezervirate termin, molimo Vas da nam dostavite ime, telefonski broj i e-mail adresu kako bismo mogli
          potvrditi Vašu rezervaciju i omogućiti kontakt u slučaju potrebe.
        </Typography>
        <Typography>PRIKUPLJANJE I KORIŠTENJE PODATAKA</Typography>
        <Typography>
          Svi Vaši osobni podaci koje nam dostavite bit će korišteni isključivo u svrhu pružanja naših usluga i zaštite
          Vašeg interesa kao klijenta. Vaši osobni podaci neće se prodavati, iznajmljivati ili dijeliti s trećim
          stranama, osim u slučajevima kada je to zakonski obavezno ili kada je to potrebno kako bismo pružili uslugu
          koju ste tražili.
        </Typography>
        <Typography>SIGURNOST PODATAKA</Typography>
        <Typography>
          Vaši osobni podaci bit će pohranjeni na siguran način i dostupni samo osoblju frizerskog salona koje je
          zaduženo za pružanje usluga. U slučaju da otkrijemo povredu sigurnosti koja utječe na Vaše osobne podatke,
          obavijestit ćemo Vas u roku od 72 sata nakon što postane poznata takva povreda.
        </Typography>
        <Typography>PRISTUP, ISPRAVAK I BRISANJE PODATAKA</Typography>
        <Typography>
          Imate pravo zatražiti pristup svojim osobnim podacima koje smo prikupili i pohranili. Također imate pravo
          zatražiti ispravak, brisanje ili ograničavanje obrade svojih osobnih podataka u skladu s važećim zakonima o
          zaštiti podataka o osobama.
        </Typography>
        <Typography>PRIHVAĆANJE POLITIKE PRIVATNOSTI</Typography>
        <Typography>
          Korištenjem naših usluga i rezervacijom termina, prihvaćate našu politiku privatnosti i korištenje Vaših
          osobnih podataka u svrhu pružanja naših usluga. Ako imate bilo kakvih pitanja ili nedoumica vezanih uz
          korištenje Vaših osobnih podataka, molimo Vas da nas kontaktirate putem e-maila ili telefona navedenih na
          našoj web stranici. Hvala Vam na povjerenju koje nam ukazujete!
        </Typography>
        <hr style={{ width: '100%' }}></hr>
        <Typography>IZMJENE USLUGE I CIJENE</Typography>
        <Typography>
          Cijene naših usluga mogu se promijeniti bez prethodne najave. Zadržavamo pravo u bilo kojem trenutku
          izmijeniti ili prekinuti Uslugu (ili bilo koji njezin dio ili sadržaj) bez najave u bilo kojem trenutku.
          Nećemo biti odgovorni ni vama ni bilo kojoj trećoj strani za bilo kakvu izmjenu, promjenu cijene, obustavu ili
          ukidanje usluge.
        </Typography>
        <Typography>TOČNOST OBRAČUNA I INFORMACIJE O RAČUNU</Typography>
        <Typography>
          Zadržavamo pravo odbiti ili modificirati bilo koju narudžbu koju pošaljete kod nas. U slučaju da izvršimo
          promjenu ili otkažemo narudžbu, obavijestit ćemo Vas kontaktiranjem e-pošte i/ili telefinskog broja navedenog
          u trenutku narudžbe.
        </Typography>
        <Typography>POGREŠKE, NETOČNOSTI I PROPUSTI</Typography>
        <Typography>
          Povremeno na našoj web stranici ili u Usluzi mogu postojati informacije koje sadrže tiskarske pogreške,
          netočnosti ili propuste koji se mogu odnositi na opise usluga, cijene, promocije, ponude. Pridržavamo pravo
          ispravljanja pogrešaka, netočnosti ili propusta te promjene ili ažuriranja podataka ili otkazivanja narudžbi
          ako su bilo koje informacije u Usluzi ili na bilo kojem povezanom web mjestu netočne u bilo kojem trenutku bez
          prethodne najave (uključujući nakon što ste predali narudžbu). Ne preuzimamo obvezu ažurirati, izmijeniti ili
          pojasniti informacije u Usluzi ili na bilo kojem povezanom web mjestu, uključujući, bez ograničenja,
          informacije o cijenama, osim ako to zahtijeva zakon. Nijedan određeni datum ažuriranja ili osvježavanja
          primijenjen u Usluzi ili na bilo kojem povezanom web mjestu ne smije biti naznačen da su svi podaci u Usluzi
          ili na bilo kojem povezanom web mjestu izmijenjeni ili ažurirani.
        </Typography>
        <hr style={{ width: '100%' }}></hr>
        <Typography>ZABRANJENE UPORABE</Typography>
        <Typography>
          Uz ostale zabrane navedene u Uvjetima pružanja usluge, zabranjeno vam je korištenje web mjesta ili njegovog
          sadržaja: (a) u bilo koju nezakonitu svrhu; (b) tražiti druge da izvrše ili sudjeluju u bilo kakvim
          nezakonitim radnjama; (c) kršiti bilo kakve međunarodne, savezne, provincijske ili državne propise, pravila,
          zakone ili lokalne uredbe; (d) kršiti ili kršiti naša prava intelektualnog vlasništva ili prava intelektualnog
          vlasništva drugih; (e) uznemiravati, zlostavljati, vrijeđati, štetiti, klevetati, klevetati, omalovažavati,
          zastrašivati ili diskriminirati na temelju spola, seksualne orijentacije, vjere, etničke pripadnosti, rase,
          dobi, nacionalnog podrijetla ili invaliditeta; (f) podnošenje lažnih ili obmanjujućih podataka; (g) za
          prijenos ili prijenos virusa ili bilo koje druge vrste zlonamjernog koda koji će ili se može koristiti na bilo
          koji način koji će utjecati na funkcionalnost ili rad Usluge ili bilo koje povezane web stranice, drugih web
          stranica ili Interneta; (h) za prikupljanje ili praćenje osobnih podataka drugih; (i) spam, phish, pharm,
          pretext, spider, crawl, ili scrape; (j) u bilo koju opscenu ili nemoralnu svrhu; ili (k) ometati ili
          zaobilaziti sigurnosne značajke Usluge ili bilo koje povezane web stranice, drugih web stranica ili Interneta.
          Zadržavamo pravo ukinuti vašu upotrebu Usluge ili bilo koje povezane web stranice zbog kršenja bilo koje
          zabranjene upotrebe.
        </Typography>
        <Typography>ODRICANJE GARANCIJE; OGRANIČENJE ODGOVORNOSTI</Typography>
        <Typography>
          Ne jamčimo, ne zastupamo niti jamčimo da će vaša upotreba naše usluge biti neprekinuta, pravodobna, sigurna
          ili bez pogrešaka. Ne jamčimo da će rezultati koji se mogu dobiti korištenjem usluge biti točni ili pouzdani.
          Slažete se da s vremena na vrijeme možemo ukloniti uslugu na neodređeno vrijeme ili je otkazati u bilo kojem
          trenutku, bez prethodne najave. Izričito se slažete da je vaša upotreba usluge ili nemogućnost korištenja
          usluge na vaš vlastiti rizik. Usluga i svi proizvodi i usluge koji vam se isporučuju putem usluge pružaju se
          (osim kako smo to izričito naveli) "takvi kakvi jesu" i "dostupni" za vašu upotrebu, bez ikakvog
          predstavljanja, jamstava ili bilo kakvih uvjeta, bilo izričitih ili podrazumijeva, uključujući sva implicirana
          jamstva ili uvjete prodajnosti, prodajne kvalitete, podobnosti za određenu svrhu, trajnosti, naslova i
          nekršenja. Ni u kojem slučaju Zlatni češalj, naši direktori, službenici, zaposlenici, pridružene tvrtke,
          agenti, izvođači, pripravnici, dobavljači, pružatelji usluga ili davatelji licence neće biti odgovorni za bilo
          kakvu ozljedu, gubitak, zahtjev ili bilo koju izravnu, neizravnu, slučajnu, kaznenu, posebnu, ili posljedične
          štete bilo koje vrste, uključujući, bez ograničenja, izgubljenu dobit, izgubljeni prihod, izgubljenu
          ušteđevinu, gubitak podataka, troškove zamjene ili bilo koju sličnu štetu, bilo da se temelji na ugovoru,
          deliktu (uključujući nemar), strogoj odgovornosti ili na bilo koji drugi način koji proizlazi iz vaše
          korištenje bilo koje usluge ili bilo kojeg proizvoda nabavljenog korištenjem usluge, ili za bilo koji drugi
          zahtjev povezan na bilo koji način s vašom uporabom usluge ili bilo kojeg proizvoda, uključujući, ali bez
          ograničenja, bilo kakve pogreške ili propuste u bilo kojem sadržaju, ili bilo koji gubitak ili oštećenje bilo
          koje vrste nastalo kao rezultat upotrebe usluge ili bilo kojeg sadržaja (ili proizvoda) objavljenog,
          prenošenog ili dostupnog na drugi način putem usluge, čak i ako je obaviješteno o njihovoj mogućnosti. Budući
          da neke države ili jurisdikcije ne dopuštaju izuzeće ili ograničenje odgovornosti za posljedične ili slučajne
          štete, u takvim državama ili jurisdikcijama naša odgovornost bit će ograničena u najvećoj mjeri dopuštenoj
          zakonom.
        </Typography>
        <Typography>RAZDJELJIVOST</Typography>
        <Typography>
          U slučaju da se utvrdi da je bilo koja odredba ovih Uvjeta pružanja usluge nezakonita, nevažeća ili
          neprovediva, takva će odredba ipak biti izvršna u najvećoj mjeri dopuštenoj važećim zakonom, a neizvršljivi
          dio smatrat će se odvojenim od ovih Uvjeta Usluga, takvo utvrđivanje neće utjecati na valjanost i izvršnost
          bilo kojih ostalih preostalih odredbi.
        </Typography>
        <Typography>PRESTANAK</Typography>
        <Typography>
          Obveze i obveze stranaka nastale prije datuma raskida ostat će na snazi nakon raskida ovog sporazuma u sve
          svrhe. Ovi Uvjeti pružanja usluge vrijede osim ako ih ne raskinete vi ili mi. Ove Uvjete pružanja usluge
          možete prekinuti u bilo kojem trenutku tako što ćete nas obavijestiti da više ne želite koristiti naše Usluge
          ili kad prestanete koristiti našu web stranicu. Ako prema našoj jedinoj prosudbi ne budete poštivali bilo koji
          uvjet ili odredbu ovih Uvjeta pružanja usluge ili sumnjamo da niste uspjeli, također možemo u bilo kojem
          trenutku raskinuti ovaj ugovor bez najave, a vi ćete i dalje odgovarati za sve dospjele iznose do datuma
          raskida, uključujući; i / ili u skladu s tim može vam uskratiti pristup našim Uslugama (ili bilo kojem
          njihovom dijelu).
        </Typography>
        <Typography>CIJELI SPORAZUM</Typography>
        <Typography>
          Neuspjeh u provedbi bilo kojeg prava ili odredbe ovih Uvjeta pružanja usluge neće predstavljati odricanje od
          tog prava ili odredbe. Ovi Uvjeti pružanja usluge i bilo koja pravila ili operativna pravila koja smo objavili
          na ovom mjestu ili u vezi s Uslugom predstavljaju cjelokupni sporazum i razumijevanje između vas i nas i
          uređuju vašu upotrebu Usluge, zamjenjujući bilo kakve prethodne ili sadašnje dogovore, komunikacije i
          prijedloge, usmeno ili pismeno, između vas i nas (uključujući, ali ne ograničavajući se na prethodne verzije
          Uvjeta pružanja usluge).
        </Typography>
        <Typography>MJERODAVNO PRAVO</Typography>
        <Typography>
          Ovim Uvjetima pružanja usluge i bilo kojim odvojenim ugovorima kojima vam pružamo Usluge upravljat će se i
          tumačiti u skladu sa hrvatskim zakonima.
        </Typography>
        <Typography>PROMJENE UVJETA USLUGE</Typography>
        <Typography>
          Na ovoj stranici možete u bilo kojem trenutku pregledati najnoviju verziju Uvjeta pružanja usluge. Zadržavamo
          pravo, prema vlastitom nahođenju, ažurirati, izmijeniti ili zamijeniti bilo koji dio ovih Uvjeta pružanja
          usluge objavljivanjem ažuriranja i izmjena na našoj web stranici. Vaša je odgovornost povremeno provjeravati
          naše web mjesto radi promjena. Vaša daljnja upotreba ili pristup našoj web stranici ili Usluzi nakon
          objavljivanja bilo kakvih promjena ovih Uvjeta pružanja usluge prihvaća te promjene.
        </Typography>
        <Typography>KONTAKT INFORMACIJE</Typography>
        <Typography>
          Pitanja o Uvjetima pružanja usluge možete uputiti na zlatnicesalj@yahoo.com. Zlatni češalj, Jurja Dobrile 10,
          52352, Kanfanar, OIB 36978850685
        </Typography>
      </Stack>
    </Stack>
  );
}
