import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { AppButton } from '../AppButton';

export interface IErrorFallbackProps {
  error: Error;
}

export const ErrorFallback: FC<IErrorFallbackProps> = ({ error }) => {
  return (
    <Stack gap={2} alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'} p={3}>
      <Typography variant="h4">Oops!</Typography>
      <Typography variant="h6">Nešto je pošlo po krivu</Typography>
      <Typography>{error.message}</Typography>
      <AppButton variant="contained" size="large" fullWidth onClick={() => (window.location.href = '/')}>
        Natrag na početnu stranicu
      </AppButton>
    </Stack>
  );
};

export const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Outlet />
  </ErrorBoundary>
);
