import { Stack, StackProps, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';
import { styled } from '@mui/material/styles';

const Container = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90%',
  maxHeight: '90%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.secondary.main,
  maxWidth: 720,
}));

export const ModalContent: FC<{
  children: React.ReactNode;
  icon: typeof SvgIcon | (() => JSX.Element);
  iconColor?: string;
  title: string;
  description: string;
}> = (props) => {
  return (
    <Container gap={2.5} padding={2.5} borderRadius={2.8} alignItems={'center'} overflow={'auto'}>
      <props.icon sx={{ color: props.iconColor, width: 80, height: 80 }} />
      <Typography textTransform={'capitalize'} textAlign={'center'} variant="h6" component="h2">
        {props.title}
      </Typography>
      <Typography textAlign={'center'} variant="body2">
        {props.description}
      </Typography>

      {props.children}
    </Container>
  );
};
