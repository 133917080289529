import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Modal, Stack } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppScreenInfo } from '~/components/AppScreenInfo/AppScreenInfo';
import { AppointmentSummary } from '~/components/AppointmentSummary/AppointmentSummary';
import { ModalContent } from '~/components/ModalContent/ModalContent';
import { AuthContext } from '~/contexts/AuthContext';
import { useAppointmentsQuery, useCancelAppointmentMutation } from '~/graphql/generated';

export const AppointmentReview: FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmed, setOpenConfirmed] = useState(false);

  const [cancelAppointment, { loading }] = useCancelAppointmentMutation({
    onCompleted(data) {
      if (data.cancelAppointment?.id) {
        setOpenConfirm(false);
        setOpenConfirmed(true);
      }
    },
  });

  const { data } = useAppointmentsQuery({
    variables: {
      where: {
        id: {
          equals: Number(params.id),
        },
      },
    },
  });

  const appointment = data?.appointments[0];

  if (!appointment) {
    return <AppScreenInfo text="Termin nije pronađen" />;
  }

  return (
    <>
      <Modal open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <div>
          <ModalContent
            title="Otkazivanje termina"
            description="Molimo Vas da potvrdite zahtjev za otkazivanjem termina."
            icon={DeleteForeverIcon}
            iconColor={'primary.main'}
          >
            <AppButton
              fullWidth
              variant="contained"
              color="error"
              loading={loading}
              onClick={() => {
                cancelAppointment({
                  variables: {
                    where: {
                      id: Number(params.id),
                    },
                  },
                });
              }}
            >
              potvrdi
            </AppButton>

            <AppButton fullWidth variant="contained" onClick={() => setOpenConfirm(false)}>
              natrag
            </AppButton>
          </ModalContent>
        </div>
      </Modal>
      <Modal
        open={openConfirmed}
        onClose={() => {
          setOpenConfirmed(false);
          navigate('/');
        }}
      >
        <div>
          <ModalContent
            title="Termin otkazan"
            description="Nadamo se da se vidimo uskoro drugom prilikom!"
            icon={CheckCircleIcon}
            iconColor={'success.main'}
          >
            <AppButton
              fullWidth
              variant="contained"
              onClick={() => {
                setOpenConfirmed(false);
                navigate('/appointments', {
                  replace: true,
                });
              }}
            >
              Natrag na termine
            </AppButton>
          </ModalContent>
        </div>
      </Modal>
      <AppHeader title="Detalji rezervacije" />
      <Stack gap={0.625} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
        <AppointmentSummary
          review
          key={`${appointment.id}-appointment`}
          button={{
            text: 'Pregled',
            props: {
              variant: 'outlined',
            },
          }}
          start={appointment.start}
          end={appointment.end}
          serviceName={appointment.service.name}
          duration={appointment.service.duration}
          price={appointment.service.price}
          barberFullName={appointment.barber.name}
          serviceDescription={appointment.service.description}
        />

        <AppButton
          variant="contained"
          disabled={userData?.blocked}
          color="error"
          sx={{ width: '80%' }}
          onClick={() => setOpenConfirm(true)}
        >
          otkaži
        </AppButton>
      </Stack>
    </>
  );
};
