import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { ServicesQuery } from '~/graphql/generated';

export const ServiceComponent: FC<{
  service: ServicesQuery['services'][0];
  onClick: () => void;
}> = (props) => {
  return (
    <Box bgcolor={'secondary.main'} paddingX={1.5} paddingY={2} borderRadius={2}>
      <Stack gap={1} onClick={props.onClick}>
        <Typography variant="body1" fontWeight={'500'}>
          {props.service.name}
        </Typography>

        <Typography variant="body2" color={'info.main'}>
          {props.service.description}
        </Typography>

        <Stack gap={1} justifyContent={'space-between'} direction={'row'}>
          <Stack>
            <Typography variant="body1" fontWeight={'500'} color={'primary.main'}>
              {props.service.price} €
            </Typography>
          </Stack>
          <Typography variant="body1" fontWeight={'500'}>
            {props.service.duration} min
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
