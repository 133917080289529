import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IAppHeaderProps {
  title?: string;
  onClick?: () => void;
}

export const AppHeader: FC<IAppHeaderProps> = (props) => {
  const navigate = useNavigate();
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: 'secondary.main',
      }}
    >
      <Toolbar
        sx={{
          minHeight: 50,
        }}
      >
        <IconButton
          size="medium"
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mr: 2 }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        {props.title && (
          <Typography
            variant="body1"
            component="div"
            fontWeight={'bold'}
            sx={{ flexGrow: 1, textAlign: 'center', marginRight: 6 }}
          >
            {props.title}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};
