import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { addDays, addWeeks, format, isBefore, isSameDay, lastDayOfWeek, startOfWeek, subWeeks } from 'date-fns';
import React, { useEffect, useState } from 'react';

interface CalendarProps {
  onDayClickHandle: (day: Date) => void;
  onWeekChangeHandle: (dates: Date[]) => void;
  disabledDates?: Date[];
}

export const WeekCalendar: React.FC<CalendarProps> = ({ onDayClickHandle, onWeekChangeHandle, disabledDates }) => {
  const getWeekDates = (date: Date) => {
    const startDate = startOfWeek(date, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(date, { weekStartsOn: 1 });

    const weekDates: Date[] = [];

    let day = startDate;

    while (day <= endDate) {
      weekDates.push(day);
      day = addDays(day, 1);
    }

    return weekDates;
  };

  const [currentWeekDay, setCurrentWeekDay] = useState<Date>(new Date());
  // const [currentWeek, setCurrentWeek] = useState<number>(getWeek(currentWeekDay));
  const [currentWeekDates, setCurrentWeekDates] = useState<Date[]>(getWeekDates(currentWeekDay));
  const [selectedDate, setSelectedDate] = useState<Date>(currentWeekDay);

  useEffect(() => {
    const newWeekDates = getWeekDates(currentWeekDay);
    setCurrentWeekDates(newWeekDates);
    onWeekChangeHandle(newWeekDates);
  }, [currentWeekDay, onWeekChangeHandle]);

  const changeWeekHandle = (btnType: 'prev' | 'next'): void => {
    if (btnType === 'prev') {
      setCurrentWeekDay((prevMonth) => subWeeks(prevMonth, 1));
    }
    if (btnType === 'next') {
      setCurrentWeekDay((prevMonth) => addWeeks(prevMonth, 1));
    }
  };

  const onDateClickHandle = (day: Date): void => {
    setSelectedDate(day);
    onDayClickHandle(day);
  };

  const renderHeader = (): JSX.Element => {
    const dateFormat = 'MMM yyyy';

    const date = subWeeks(currentWeekDay, 1);
    const endDate = lastDayOfWeek(date, { weekStartsOn: 1 });

    const isDisabled = isBefore(endDate, new Date()) && !isSameDay(endDate, new Date());

    return (
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton disabled={isDisabled} onClick={() => changeWeekHandle('prev')}>
          <ArrowBackIosNew />
        </IconButton>
        <Typography variant="body1" fontWeight={'bold'}>
          {format(currentWeekDay, dateFormat)}
        </Typography>
        <IconButton onClick={() => changeWeekHandle('next')}>
          <ArrowForwardIos />
        </IconButton>
      </Stack>
    );
  };

  const renderCalendar = (): JSX.Element => {
    const dateNameFormat = 'EEE';
    const dateFormat = 'd';

    const days: JSX.Element[] = [];

    for (const day of currentWeekDates) {
      const formattedDate = format(day, dateFormat);
      const cloneDay = day;

      const isDisabled =
        (isBefore(day, new Date()) && !isSameDay(day, new Date())) ||
        (disabledDates && disabledDates.find((date) => isSameDay(date, day)));

      days.push(
        <Box
          bgcolor={isSameDay(day, selectedDate) ? 'primary.main' : ''}
          key={day.toString()}
          onClick={() => {
            if (isDisabled) return;
            onDateClickHandle(cloneDay);
          }}
          px={0.625}
          py={1.5}
          borderRadius={20}
          flex={'1 0 0px'}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="body2"
            fontWeight={'600'}
            align="center"
            color={isDisabled ? 'action.disabled' : undefined}
          >
            {format(day, dateNameFormat)}
          </Typography>
          <Typography
            variant="body2"
            fontWeight={'600'}
            align="center"
            color={isDisabled ? 'action.disabled' : undefined}
          >
            {formattedDate}
          </Typography>
        </Box>,
      );
    }

    return (
      <Stack>
        <Stack direction="row" justifyContent={'space-evenly'}>
          {days}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack>
      {renderHeader()}
      {renderCalendar()}
    </Stack>
  );
};

export default WeekCalendar;
