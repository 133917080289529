/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { BarbersQuery, ServicesQuery } from '~/graphql/generated';

// TODO: fix types
export interface IBookingContext {
  service?: ServicesQuery['services'][0];
  barber?: BarbersQuery['barbers'][0];
  selectedTime?: {
    start: Date;
    end: Date;
    date: Date;
  };
  selectService: (service: ServicesQuery['services'][0]) => void;
  selectBarber: (barber: BarbersQuery['barbers'][0]) => void;
  selectTime: (date: Date, start: Date, end: Date) => void;
}

export const BookingContext = createContext<IBookingContext>({
  selectService: () => {
    //
  },
  selectBarber: () => {
    //
  },
  selectTime: () => {
    //
  },
});
