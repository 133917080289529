import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

export const AppScreenInfo: FC<{ text?: string; children?: React.ReactNode }> = (props) => {
  return (
    <Box
      sx={{
        padding: 2,
        paddingX: 3,
        backgroundColor: 'secondary.main',
      }}
    >
      {props.children ? (
        props.children
      ) : (
        <Typography textAlign={'center'} variant="body1" fontWeight={'500'} color={'info.main'}>
          {props.text}
        </Typography>
      )}
    </Box>
  );
};
