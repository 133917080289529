export const FIREBASE_AUTH_ERROR_DESCRIPTIONS = {
  'auth/admin-restricted-operation': 'Operacija dostupna samo administratorima',
  'auth/argument-error': 'Pogrešan argument',
  'auth/app-not-authorized': 'Aplikacija nije ovlaštena',
  'auth/app-not-installed': 'Aplikacija nije instalirana',
  'auth/captcha-check-failed': 'Provjera Captche nije uspjela',
  'auth/code-expired': 'Kod je istekao',
  'auth/cordova-not-ready': 'Cordova nije spremna',
  'auth/cors-unsupported': 'CORS nije podržan',
  'auth/credential-already-in-use': 'Vjerodajnica već u upotrebi',
  'auth/custom-token-mismatch': 'Neispravno podudaranje prilagođenog tokena',
  'auth/requires-recent-login': 'Potrebna je nedavna prijava',
  'auth/dependent-sdk-initialized-before-auth': 'SDK inicijaliziran prije autentikacije',
  'auth/dynamic-link-not-activated': 'Dinamička poveznica nije aktivirana',
  'auth/email-change-needs-verification': 'Promjena e-pošte zahtijeva verifikaciju',
  'auth/email-already-in-use': 'E-pošta već u upotrebi',
  'auth/emulator-config-failed': 'Konfiguracija emulatora nije uspjela',
  'auth/expired-action-code': 'Istekao kod radnje',
  'auth/cancelled-popup-request': 'Zahtjev za iskačućim prozorom otkazan',
  'auth/internal-error': 'Unutarnja greška',
  'auth/invalid-api-key': 'Neispravan API ključ',
  'auth/invalid-app-credential': 'Neispravna vjerodajnica aplikacije',
  'auth/invalid-app-id': 'Neispravan ID aplikacije',
  'auth/invalid-user-token': 'Neispravan korisnički token',
  'auth/invalid-auth-event': 'Neispravan događaj autentikacije',
  'auth/invalid-cert-hash': 'Neispravna hash vrijednost certifikata',
  'auth/invalid-verification-code': 'Neispravan verifikacijski kod',
  'auth/invalid-continue-uri': 'Neispravan URI za nastavak',
  'auth/invalid-cordova-configuration': 'Neispravna Cordova konfiguracija',
  'auth/invalid-custom-token': 'Neispravan prilagođeni token',
  'auth/invalid-dynamic-link-domain': 'Neispravna domena dinamičke poveznice',
  'auth/invalid-email': 'Neispravna e-pošta',
  'auth/invalid-emulator-scheme': 'Neispravan shema emulatora',
  'auth/invalid-credential': 'Neispravna vjerodajnica',
  'auth/invalid-message-payload': 'Neispravan payload poruke',
  'auth/invalid-multi-factor-session': 'Neispravna sesija više faktora',
  'auth/invalid-oauth-client-id': 'Neispravan ID klijenta OAuth',
  'auth/invalid-oauth-provider': 'Neispravan pružatelj OAuth',
  'auth/invalid-action-code': 'Neispravan kod radnje',
  'auth/unauthorized-domain': 'Nedozvoljena domena',
  'auth/wrong-password': 'Pogrešna lozinka',
  'auth/invalid-persistence-type': 'Neispravan tip trajnosti',
  'auth/invalid-phone-number': 'Neispravan broj telefona',
  'auth/invalid-provider-id': 'Neispravan ID pružatelja',
  'auth/invalid-recipient-email': 'Neispravna e-pošta primatelja',
  'auth/invalid-sender': 'Neispravan pošiljatelj',
  'auth/invalid-verification-id': 'Neispravan ID verifikacije',
  'auth/invalid-tenant-id': 'Neispravan ID najmoprimca',
  'auth/multi-factor-info-not-found': 'Informacije više faktora nisu pronađene',
  'auth/multi-factor-auth-required': 'Potrebna je autentikacija više faktora',
  'auth/missing-android-pkg-name': 'Nedostaje naziv paketa za Android',
  'auth/missing-app-credential': 'Nedostaje vjerodajnica aplikacije',
  'auth/auth-domain-config-required': 'Potrebna je konfiguracija domene autentikacije',
  'auth/missing-verification-code': 'Nedostaje verifikacijski kod',
  'auth/missing-continue-uri': 'Nedostaje URI za nastavak',
  'auth/missing-iframe-start': 'Nedostaje početak iframe-a',
  'auth/missing-ios-bundle-id': 'Nedostaje iOS ID paketa',
  'auth/missing-or-invalid-nonce': 'Nedostaje ili je neispravan nonce',
  'auth/missing-multi-factor-info': 'Nedostaju informacije više faktora',
  'auth/missing-multi-factor-session': 'Nedostaje sesija više faktora',
  'auth/missing-phone-number': 'Nedostaje broj telefona',
  'auth/missing-verification-id': 'Nedostaje ID verifikacije',
  'auth/app-deleted': 'Aplikacija je izbrisana',
  'auth/account-exists-with-different-credential': 'Račun već postoji s drugom vjerodajnicom',
  'auth/network-request-failed': 'Neuspjelo mrežno zahtjev',
  'auth/null-user': 'Null korisnik',
  'auth/no-auth-event': 'Nema događaja autentikacije',
  'auth/no-such-provider': 'Nema takvog pružatelja',
  'auth/operation-not-allowed': 'Operacija nije dozvoljena',
  'auth/operation-not-supported-in-this-environment': 'Operacija nije podržana u ovom okruženju',
  'auth/popup-blocked': 'Iskačući prozor blokiran',
  'auth/popup-closed-by-user': 'Iskačući prozor zatvoren od strane korisnika',
  'auth/provider-already-linked': 'Pružatelj već povezan',
  'auth/quota-exceeded': 'Premašen limit',
  'auth/redirect-cancelled-by-user': 'Preusmjeravanje otkazano od strane korisnika',
  'auth/redirect-operation-pending': 'Preusmjeravanje u tijeku',
  'auth/rejected-credential': 'Odbijena vjerodajnica',
  'auth/second-factor-already-in-use': 'Drugi faktor već u upotrebi',
  'auth/maximum-second-factor-count-exceeded': 'Premašen maksimalan broj drugih faktora',
  'auth/tenant-id-mismatch': 'Neslaganje ID najmoprimca',
  'auth/timeout': 'Isteklo vrijeme',
  'auth/user-token-expired': 'Token korisnika istekao',
  'auth/too-many-requests': 'Previše zahtjeva, pokušajte kasnije',
  'auth/unauthorized-continue-uri': 'Neovlašteni URI za nastavak',
  'auth/unsupported-first-factor': 'Nepodržan prvi faktor',
  'auth/unsupported-persistence-type': 'Nepodržani tip trajnosti',
  'auth/unsupported-tenant-operation': 'Nepodržana operacija najmoprimca',
  'auth/unverified-email': 'Neverificirana e-pošta',
  'auth/user-cancelled': 'Korisnik je otkazao',
  'auth/user-not-found': 'Korisnik nije pronađen',
  'auth/user-disabled': 'Korisnik onemogućen',
  'auth/user-mismatch': 'Neslaganje korisnika',
  'auth/user-signed-out': 'Korisnik se odjavio',
  'auth/weak-password': 'Slaba lozinka',
  'auth/web-storage-unsupported': 'Web pohrana nije podržana',
  'auth/already-initialized': 'Već inicijalizirano',
  'auth/recaptcha-not-enabled': 'reCAPTCHA nije omogućena',
  'auth/missing-recaptcha-token': 'Nedostaje reCAPTCHA token',
  'auth/invalid-recaptcha-token': 'Neispravan reCAPTCHA token',
  'auth/invalid-recaptcha-action': 'Neispravna reCAPTCHA akcija',
  'auth/missing-client-type': 'Nedostaje vrsta klijenta',
  'auth/missing-recaptcha-version': 'Nedostaje verzija reCAPTCHA',
  'auth/invalid-recaptcha-version': 'Neispravna verzija reCAPTCHA',
  'auth/invalid-req-type': 'Neispravan tip zahtjeva',
};
