import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BottomNavigation, { BottomNavigationProps } from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const AppBottomNavigation: FC<{ bottomNavigationProps?: BottomNavigationProps }> = (props) => {
  const location = useLocation();
  const setInitialValue = useCallback(() => {
    if (location.pathname.startsWith('/appointments')) {
      return 1;
    }
    if (location.pathname.startsWith('/booking')) {
      return 2;
    }
    if (location.pathname.startsWith('/settings')) {
      return 3;
    }
    return 0;
  }, [location.pathname]);

  const [value, setValue] = useState(setInitialValue());
  const navigate = useNavigate();

  useEffect(() => {
    setValue(setInitialValue());
  }, [location.pathname, setInitialValue]);

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
          navigate('/');
        }
        if (newValue === 1) {
          navigate('/appointments');
        }
        if (newValue === 2) {
          navigate('/booking');
        }
        if (newValue === 3) {
          navigate('/settings');
        }
      }}
      {...props.bottomNavigationProps}
    >
      <BottomNavigationAction label="Početna" icon={<HomeOutlinedIcon />} />
      <BottomNavigationAction label="Termini" icon={<AccessTimeOutlinedIcon />} />
      <BottomNavigationAction label="Rezervacija" icon={<CalendarMonthOutlinedIcon />} />
      <BottomNavigationAction label="Settings" icon={<SettingsOutlinedIcon />} />
    </BottomNavigation>
  );
};
