import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';

export interface IFavouriteService {
  description?: string | undefined | null;
  name: string | undefined;
  favourite?: boolean;
  onClick?: () => void;
}

export const AppFavourite: FC<IFavouriteService> = (props) => {
  return (
    <Stack bgcolor={'secondary.main'} p={3} onClick={props.onClick}>
      <Stack gap={props.description ? 1 : 0}>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontSize={16} fontWeight={500} color={'#F5F5F5'}>
            {props.name}
          </Typography>
          <FavoriteRoundedIcon sx={{ color: props.favourite ? 'primary.dark' : 'action.disabled' }} />
        </Stack>
        <Typography fontSize={14} fontWeight={400} color={' #939480'}>
          {props.description}
        </Typography>
      </Stack>
    </Stack>
  );
};
