import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Container, Modal, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { ModalContent } from '~/components/ModalContent/ModalContent';
import { OtpCode } from '~/components/OtpCode/OtpCode';
import { useUpdateProfileMutation } from '~/graphql/generated';
import { errorFormatter } from '~/helpers/utils';
import { useUpdatePhoneSendCode } from './useUpdatePhone';

export interface PhoneVerificationLocationState {
  phone: string;
}
export const PhoneVerification: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { phone } = location.state as PhoneVerificationLocationState;

  const [updateProfile, { loading: updateProfileLoading, error: updateProfileError }] = useUpdateProfileMutation({
    onCompleted(data) {
      if (data.updateProfile.phone === phone) {
        setOpenModal(true);
      }
    },
  });

  const handleModal = () => {
    setOpenModal(false);
    navigate('/settings');
  };

  const { updatePhoneNumberFn, sendVerifyCodeFn } = useUpdatePhoneSendCode(phone || '', () => {
    updateProfile({
      variables: {
        data: {
          phone: phone,
        },
      },
    });
  });

  return (
    <>
      <AppHeader />
      <Container
        sx={{
          flex: 1,
          display: 'flex',
        }}
      >
        <Stack gap={10} alignItems={'center'} pt={2.5} flex={1}>
          <Typography fontWeight={'bold'} variant="h4">
            Verifikacija
          </Typography>
          <OtpCode
            onResend={() => {
              sendVerifyCodeFn.refetch();
            }}
            phone={phone}
            onSubmit={(code) => {
              updatePhoneNumberFn.mutate(code);
            }}
            loading={updatePhoneNumberFn.isLoading || sendVerifyCodeFn.isInitialLoading || updateProfileLoading}
            error={
              errorFormatter(updatePhoneNumberFn.error) ||
              errorFormatter(sendVerifyCodeFn.error) ||
              errorFormatter(updateProfileError)
            }
          />
        </Stack>
      </Container>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          navigate('/settings');
        }}
      >
        <div>
          <ModalContent
            title="Spremljeno"
            description="Uspješno ste promijenili Vaš broj mobilnog telefona."
            iconColor="success.main"
            icon={CheckCircleRoundedIcon}
            children={
              <AppButton variant="contained" size="large" fullWidth onClick={handleModal}>
                Natrag na postavke
              </AppButton>
            }
          />
        </div>
      </Modal>
    </>
  );
};
