import { LocalPhoneOutlined } from '@mui/icons-material';
import { Container, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { AppScreenInfo } from '~/components/AppScreenInfo/AppScreenInfo';
import { ServicesQuery } from '~/graphql/generated';

export interface NotBookableServiceState {
  service: ServicesQuery['services'][0];
}

export const NotBookableService: FC = () => {
  const location = useLocation();
  const state: NotBookableServiceState | undefined = location.state;

  return (
    <>
      <AppHeader />
      <AppLabel text="usluga" />
      <AppScreenInfo text={state?.service?.name || ''}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="body1" fontWeight={'500'}>
            {state?.service?.name}
          </Typography>
          <Typography variant="body1" fontWeight={'500'}>
            {state?.service?.duration} min
          </Typography>
        </Stack>
      </AppScreenInfo>

      <Container sx={{ flex: 1 }}>
        <Stack height={'100%'} alignItems={'center'} gap={2.5} justifyContent={'center'}>
          <Typography textTransform={'uppercase'} align="center" fontWeight={'bold'}>
            za odabranu uslugu se nije moguće naručiti preko aplikacije.
          </Typography>
          <Typography textTransform={'capitalize'} align="center">
            Molimo kontaktirajte nas telefonski
          </Typography>

          <Link
            // TODO: Dohvatiti iz backenda
            to={'tel:+385921121928'}
            style={{
              width: '100%',
            }}
          >
            <AppButton startIcon={<LocalPhoneOutlined />} size="large" variant="contained" fullWidth>
              Nazovi
            </AppButton>
          </Link>
        </Stack>
      </Container>
    </>
  );
};
