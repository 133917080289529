import { Box, Container, Typography } from '@mui/material';
import { FC } from 'react';

export const AppLabel: FC<{ text: string }> = (props) => {
  return (
    <Box
      sx={{
        paddingY: 1.5,
      }}
    >
      <Container>
        <Typography variant="caption" fontWeight={'bold'} color={'info.main'} textTransform={'uppercase'}>
          {props.text}
        </Typography>
      </Container>
    </Box>
  );
};
