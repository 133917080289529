import { FC, useContext } from 'react';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { AppSwitch } from '~/components/AppSwitch/AppSwitch';
import { AuthContext } from '~/contexts/AuthContext';

export const EditNotification: FC = () => {
  const context = useContext(AuthContext);
  return (
    <>
      <AppHeader />
      {/* <AppLabel text="Mobilne notifikacije" />
      <AppSwitch /> */}
      <AppLabel text="Sms" />
      <AppSwitch
        text="Za isključivanje SMS notifikacija obratite se Vašem frizeru"
        checked={!!context.userData?.smsNotification}
        disabled
      />
    </>
  );
};
