/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';

import { AppContainer } from './components/AppContainer';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import { apolloClient } from './config/apollo';
import { AuthProvider } from './contexts/AuthContext';
import { router } from './router/routes';
import { theme } from './theme';
import SnackbarCloseButton from './components/Snackbar/CloseButton';

// To resolve:
// the browsers' back/forward cache doesn't work when IndexedDB has an open connection.
// https://github.com/firebase/firebase-js-sdk/issues/6167#issue-1209069250
const open = window.indexedDB.open.bind(window.indexedDB);
const dbs: any = [];

window.indexedDB.open = (...args) => {
  const request = open(...args);

  request.addEventListener('success', (event) => {
    if ((event.target as any)?.result) {
      const db = (event.target as any).result;
      dbs.push(db);
    }
  });

  return request;
};

window.addEventListener(
  'pagehide',
  () => {
    for (const db of dbs) {
      db.close();
    }
  },
  { capture: true },
);

// end

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppContainer>
          <SnackbarProvider action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
            <ApolloProvider client={apolloClient}>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <RouterProvider router={router} />
                </AuthProvider>
              </QueryClientProvider>
            </ApolloProvider>
          </SnackbarProvider>
        </AppContainer>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
