import { GraphQLError } from 'graphql';
import { FIREBASE_AUTH_ERROR_DESCRIPTIONS } from './constants';
import { FirebaseError } from 'firebase/app';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorFormatter = (error: GraphQLError | Error | FirebaseError | null | unknown) => {
  if (!error) return '';
  if (error instanceof GraphQLError) {
    return getBackendErrorsDescription(error);
  }
  if (error instanceof FirebaseError) {
    return getFirebaseErrorsDescription(error.code);
  }
  if (error instanceof Error) {
    return error.message;
  }
  return 'Dogodila se pogreška!';
};

export function getBackendErrorsDescription(error: GraphQLError) {
  switch (error.extensions?.code) {
    case 'AppointmentNotAssignedToClient':
      return 'Termin nije dodijeljen klijentu';
    case 'AppointmentPassed':
      return 'Termin je prošao';
    case 'BarberDisabled':
      return 'Frizer je onemogućen';
    case 'BarberExists':
      return 'Frizer već postoji';
    case 'BarberNotExists':
      return 'Frizer ne postoji';
    case 'BarberNotLoggedIn':
      return 'Frizer nije prijavljen';
    case 'NotActive':
      return 'Nije aktivan';
    case 'PasswordsMissmatch':
      return 'Neslaganje lozinki';
    case 'RegistrationDataMissmatch':
      return 'Neslaganje podataka za registraciju';
    case 'ServiceNotAssignedToBarber':
      return 'Usluga nije dodijeljena frizeru';
    case 'ServiceNoDuration':
      return 'Odabrana usluga nema trajanje';
    case 'ServiceTimeSlotsMissmatch':
      return 'Neslaganje trajanja usluge s poslanim vremenskim rasponima';
    case 'TacValidation':
      return 'Uvjeti korištenja nisu valjani';
    case 'TimeSlotsNotAvailable':
      return 'Vremenski rasponi nisu dostupni';
    case 'UserBookingCollision':
      return 'Sudar rezervacija korisnika';
    case 'UserMaxAppointmentsExceeded':
      return `Korisnik je premašio maksimalan broj nadolazećih termina, maksimalan broj Vaših nadolazećih termina je ${
        import.meta.env.VITE_USER_BOOKING_MAX_APPOINTMENTS
      }`;
    case 'UserNotFound':
      return 'Korisnik nije pronađen';
    case 'UserPhoneExists':
      return 'Korisnik s brojem telefona već postoji';
    case 'UserTokenMissing':
      return 'Nedostaje token korisnika';
    case 'ValidationFailed':
      return 'Neuspjela provjera valjanosti';
    case 'AccessDenied':
      return 'Pristup odbijen';
    case 'AccessTokenExpired':
      return 'Pristupni token istekao';
    case 'ErrorDuringExecution':
      return 'Greška tijekom izvođenja';
    case 'AccessForbidden':
      return 'Pristup zabranjen';
    case 'NotAuthorized':
      return 'Nije ovlašten';
    case 'ThirdPartyError':
      return 'Greška treće strane';
    case 'TokenExpired':
      return 'Token istekao';
    case 'TokenNotValid':
      return 'Nevaljan token';
    case 'UserRefreshTokenNotValid':
      return 'Refresh token nije valjan za korisnika';

    default:
      return error.message;
  }
}

export const getFirebaseErrorsDescription = (code: string) => {
  return FIREBASE_AUTH_ERROR_DESCRIPTIONS[code as keyof typeof FIREBASE_AUTH_ERROR_DESCRIPTIONS];
};
