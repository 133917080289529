import { hr } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import React from 'react';
import ReactDOM from 'react-dom/client';
setDefaultOptions({ locale: hr });

import App from './App.tsx';
import './index.css';

import { registerSW } from 'virtual:pwa-register';

// add this to prompt for a refresh
const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm('New content available. Reload?')) {
      updateSW(true);
    }
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
