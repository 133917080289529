import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC } from 'react';

export const AppButton: FC<ButtonProps & { loading?: boolean }> = ({ loading, ...props }) => {
  return (
    <Button {...props} disabled={loading || props.disabled}>
      {loading && (
        <CircularProgress
          color="inherit"
          size={16}
          sx={{
            mr: 2,
          }}
        />
      )}
      {props.children}
    </Button>
  );
};
