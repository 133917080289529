import { useCallback, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { BarbersQuery, ServicesQuery } from '~/graphql/generated';
import { BookingContext } from './useBookingContext';

export interface IBookingLocationState {
  service: ServicesQuery['services'][0];
  barber: BarbersQuery['barbers'][0];
  selectedTime: {
    start: Date;
    end: Date;
    date: Date;
  };
}

export const BookingContextProvider = () => {
  const location = useLocation();
  const state: IBookingLocationState | undefined = location.state;

  const [service, setService] = useState<ServicesQuery['services'][0] | undefined>(state?.service);
  const [barber, setBarber] = useState<BarbersQuery['barbers'][0] | undefined>(state?.barber);
  const [selectedTime, setBookingTime] = useState<
    | {
        start: Date;
        end: Date;
        date: Date;
      }
    | undefined
  >(state?.selectedTime);

  const selectService = useCallback((service: ServicesQuery['services'][0]) => {
    setService(service);
  }, []);

  const selectBarber = useCallback((barber: BarbersQuery['barbers'][0]) => {
    setBarber(barber);
  }, []);
  const selectTime = useCallback((date: Date, start: Date, end: Date) => {
    setBookingTime({
      start,
      end,
      date,
    });
  }, []);

  const value = useMemo(
    () => ({
      service,
      selectService,
      barber,
      selectBarber,
      selectedTime,
      selectTime,
    }),
    [service, selectService, barber, selectBarber, selectedTime, selectTime],
  );

  return (
    <BookingContext.Provider value={value}>
      <Outlet />
    </BookingContext.Provider>
  );
};
