import { Checkbox, FormControlLabel, FormHelperText, Link, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ZodType, z } from 'zod';
import { AppInput } from '~/components/AppInput/AppInput';

import { zodResolver } from '@hookform/resolvers/zod';
import parsePhoneNumber from 'libphonenumber-js/mobile';
import { AppButton } from '~/components/AppButton';
import { usePhoneHook } from '~/hooks/phoneHook';
import { IVerificationState } from './Verification';

export interface IRegisterFormData {
  phone: string;
  name: string;
  termsAccepted: boolean;
}

const schema: ZodType<IRegisterFormData> = z.object({
  phone: z
    .string({
      required_error: 'Ovo polje je obavezno',
    })
    .nonempty('Ovo polje je obavezno')
    .refine((value) => {
      const phoneNumber = parsePhoneNumber(value, 'HR');

      return phoneNumber?.getType() === 'MOBILE' && phoneNumber?.isValid();
    }, 'Neispravan format mobilnog broja'),
  name: z.string().nonempty('Ovo polje je obavezno'),
  termsAccepted: z.literal<boolean>(true, {
    errorMap: () => {
      return {
        message: 'Potrebno je prihvatit uvjete za registraciju',
      };
    },
  }),
});

export default function Register() {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<IRegisterFormData>({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: {
      phone: '',
      name: '',
      termsAccepted: false,
    },
  });

  const { phoneField, mask } = usePhoneHook(control);

  const onSubmit = handleSubmit((data) => {
    navigate('/authentication/verification', {
      state: { data } as IVerificationState,
    });
  });

  return (
    <Stack flex={1} justifyContent={'space-between'} height="100%" gap={2}>
      <Stack gap={1.5}>
        <AppInput
          ref={mask.ref}
          label="Unesite broj mobitela"
          value={phoneField.field.value}
          onBlur={phoneField.field.onBlur}
          name={phoneField.field.name}
          onChange={() => {
            //
          }}
          textFieldProps={{
            placeholder: '09x xxx xx xx',
            error: !!errors.phone,
            helperText: errors.phone?.message,
          }}
        />

        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <AppInput
              label="Ime i prezime"
              {...field}
              textFieldProps={{
                error: !!errors.name,
                helperText: errors.name?.message,
              }}
            />
          )}
        />

        <Stack>
          <FormControlLabel
            control={
              <Controller
                name="termsAccepted"
                control={control}
                render={({ field }) => <Checkbox inputRef={field.ref} {...field} />}
              />
            }
            label={
              <>
                <Typography variant="body2" fontWeight={500}>
                  Pročitao sam i slažem se s{' '}
                  <Link href="/authentication/terms-and-conditions">Uvjetima i odredbama</Link>
                </Typography>
              </>
            }
          />
          {errors.termsAccepted && (
            <FormHelperText
              sx={{
                color: '#E58A8A',
                marginX: 1.75,
                mt: 0,
              }}
            >
              {errors.termsAccepted.message}
            </FormHelperText>
          )}
        </Stack>
      </Stack>

      <Stack gap={4}>
        <Stack direction={'row'} gap={0.5} justifyContent={'center'}>
          <Typography variant="body2" fontWeight={500}>
            Već imate račun?
          </Typography>
          <Typography
            variant="body2"
            fontWeight={600}
            color={'primary.main'}
            onClick={() => navigate('/authentication/login')}
          >
            Prijava
          </Typography>
        </Stack>
        <AppButton variant="contained" size="large" disabled={!isValid} onClick={onSubmit}>
          Sljedeći korak
        </AppButton>
      </Stack>
    </Stack>
  );
}
