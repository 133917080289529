import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, Typography } from '@mui/material';
import parsePhoneNumber from 'libphonenumber-js/mobile';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ZodType, z } from 'zod';
import { AppButton } from '~/components/AppButton';
import { AppInput } from '~/components/AppInput/AppInput';
import { usePhoneHook } from '~/hooks/phoneHook';
import { IVerificationState } from './Verification';

export interface ILoginFormData {
  phone: string;
}

const schema: ZodType<ILoginFormData> = z.object({
  phone: z
    .string({
      required_error: 'Ovo polje je obavezno',
    })
    .nonempty('Ovo polje je obavezno')
    .refine((value) => {
      const phoneNumber = parsePhoneNumber(value, 'HR');

      return phoneNumber?.getType() === 'MOBILE' && phoneNumber?.isValid();
    }, 'Neispravan format mobilnog broja'),
});

export default function Login() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ILoginFormData>({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: {
      phone: '',
    },
  });

  const { phoneField, mask } = usePhoneHook(control);

  const onSubmit = handleSubmit((data) => {
    navigate('/authentication/verification', {
      state: { data } as IVerificationState,
    });
  });

  return (
    <Stack flex={1} justifyContent={'space-between'} height="100%">
      <AppInput
        ref={mask.ref}
        label="Unesite broj mobitela"
        value={phoneField.field.value}
        onBlur={phoneField.field.onBlur}
        name={phoneField.field.name}
        onChange={() => {
          //
        }}
        textFieldProps={{
          placeholder: '09x xxx xx xx',
          error: !!errors.phone,
          helperText: errors.phone?.message,
        }}
      />

      <Stack gap={4}>
        <Stack direction={'row'} gap={0.5} justifyContent={'center'}>
          <Typography variant="body2" fontWeight={500}>
            Nemate račun?
          </Typography>
          <Typography
            variant="body2"
            fontWeight={600}
            color={'primary.main'}
            onClick={() => navigate('/authentication/register')}
          >
            Registracija
          </Typography>
        </Stack>
        <AppButton variant="contained" size="large" disabled={!isValid} onClick={onSubmit}>
          Pošalji sms
        </AppButton>
      </Stack>
    </Stack>
  );
}
