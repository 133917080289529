import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Modal, Skeleton, Stack, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppButton } from '~/components/AppButton';
import { AppFavourite } from '~/components/AppFavourite/AppFavourite';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { ModalContent } from '~/components/ModalContent/ModalContent';
import { useBarbersQuery, useUpdateProfileMutation } from '~/graphql/generated';
import { AuthContext } from '~/contexts/AuthContext';

export const EditFavouriteBarber: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const { userData } = useContext(AuthContext);
  const { data: barbersData, loading } = useBarbersQuery();
  const [updateProfileMutation] = useUpdateProfileMutation();

  const selectBarber = (barberId: number) => {
    updateProfileMutation({
      variables: {
        data: {
          favouriteBarberId: barberId,
        },
      },
    });
  };

  const removeBarber = () => {
    updateProfileMutation({
      variables: {
        data: {
          favouriteBarberId: null,
        },
      },
    });
  };

  const handleModal = () => {
    setOpenModal(false);
  };

  const selectedBarber = barbersData?.barbers.find((b) => {
    return b.id === userData?.favouriteBarberId;
  });

  if (loading) {
    return (
      <Stack padding={2} gap={1}>
        <Skeleton variant="rounded" width={'100%'} height={100} />
      </Stack>
    );
  }

  return (
    <>
      <Modal open={openModal}>
        <div>
          <ModalContent
            title="Informacija"
            description="Odabir omiljenog frizera/frizerke omogućuje Vam brži način rezervacije termina. Prilikom rezervacije termina vaš omiljeni frizer/frizerka će se postaviti kao početno odabrani, te je nepotrebno  izvršiti odabir frizera."
            iconColor="action.disabled"
            icon={InfoRoundedIcon}
            children={
              <AppButton variant="contained" size="large" fullWidth onClick={handleModal}>
                Zatvori
              </AppButton>
            }
          />
        </div>
      </Modal>
      <AppHeader />
      <AppLabel text="Omiljeni frizer/frizerka" />
      {selectedBarber ? (
        <AppFavourite name={selectedBarber.name} favourite onClick={() => removeBarber()} />
      ) : (
        <Box bgcolor={'secondary.main'} p={3}>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography fontSize={15} fontWeight={500} color={'info.main'}>
              Nemate omiljenog frizera/frizerku
            </Typography>
            <InfoRoundedIcon sx={{ color: 'info.main' }} onClick={() => setOpenModal(true)} />
          </Stack>
        </Box>
      )}
      <AppLabel text="frizeri" />
      <Stack gap={1}>
        {barbersData?.barbers
          .filter((b) => b.id !== userData?.favouriteBarberId)
          .map((barber) => (
            <AppFavourite key={`barber.id-${barber.id}`} name={barber.name} onClick={() => selectBarber(barber.id)} />
          ))}
      </Stack>
    </>
  );
};
