import { Stack } from '@mui/material';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OtpCode } from '~/components/OtpCode/OtpCode';
import { firebaseAuth } from '~/config/firebase';
import { AuthContext } from '~/contexts/AuthContext';
import { useMeDataLazyQuery, useRegisterMutation } from '~/graphql/generated';
import { errorFormatter } from '~/helpers/utils';
import { useSignInWithPhoneNumber } from '~/hooks/usePhoneVerify';
import { ILoginFormData } from './Login';
import { IRegisterFormData } from './Register';

export interface IVerificationState {
  data: IRegisterFormData | ILoginFormData;
}

export default function Verification() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const state: IVerificationState | undefined = location.state;

  const data = state?.data;

  const [registerMutation, { error: registerError, loading: registerLoading }] = useRegisterMutation({
    async onError() {
      await firebaseAuth.signOut();
    },
    async onCompleted(data) {
      if (data?.register.id) {
        context.setUserProfile(data.register);

        navigate('/');
      } else {
        await firebaseAuth.signOut();
      }
    },
  });

  const [getMeData, { loading: meLoading, error: meError }] = useMeDataLazyQuery({
    async onError() {
      await firebaseAuth.signOut();
    },
    async onCompleted(data) {
      if (data?.user?.id) {
        context.setUserProfile(data.user);
        navigate('/');
      } else {
        await firebaseAuth.signOut();
      }
    },
  });

  const { signInWithPhoneNumberMutation, sendVerifyCodeQuery } = useSignInWithPhoneNumber(
    state?.data.phone || '',
    async (userCredential) => {
      const isRegister = data && 'name' in data;
      if (!userCredential) return;

      if (isRegister) {
        const { name, phone, termsAccepted } = data;
        registerMutation({
          variables: {
            data: {
              name,
              phone,
              userUID: userCredential.user.uid,
              idToken: await userCredential.user.getIdToken(),
              tac: termsAccepted,
            },
          },
        });
      } else {
        getMeData();
      }
    },
  );

  return (
    <>
      <Stack flex={1} justifyContent={'space-between'} height="100%">
        <OtpCode
          onSubmit={(code) => {
            signInWithPhoneNumberMutation.mutate(code);
          }}
          onResend={() => {
            if (state?.data.phone) {
              sendVerifyCodeQuery.refetch();
            }
          }}
          phone={state?.data.phone || ''}
          loading={sendVerifyCodeQuery.isInitialLoading || meLoading || registerLoading}
          error={
            errorFormatter(signInWithPhoneNumberMutation.error) ||
            errorFormatter(sendVerifyCodeQuery.error) ||
            errorFormatter(meError) ||
            errorFormatter(registerError)
          }
        />
      </Stack>
    </>
  );
}
