import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Modal, Skeleton, Stack, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppButton } from '~/components/AppButton';
import { AppFavourite } from '~/components/AppFavourite/AppFavourite';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { ModalContent } from '~/components/ModalContent/ModalContent';
import { AuthContext } from '~/contexts/AuthContext';
import { useServicesQuery, useUpdateProfileMutation } from '~/graphql/generated';

export const EditFavouriteService: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const { data, loading } = useServicesQuery();
  const { userData } = useContext(AuthContext);
  const [updateProfileMutation] = useUpdateProfileMutation();

  const selectService = (serviceId: number) => {
    updateProfileMutation({
      variables: {
        data: {
          favouriteServiceId: serviceId,
        },
      },
    });
  };

  const removeService = () => {
    updateProfileMutation({
      variables: {
        data: {
          favouriteServiceId: null,
        },
      },
    });
  };

  const handleModal = () => {
    setOpenModal(false);
  };

  const selectedService = data?.services.find((service) => service.id === userData?.favouriteServiceId);

  if (loading) {
    return (
      <Stack padding={2} gap={1}>
        <Skeleton variant="rounded" width={'100%'} height={100} />
      </Stack>
    );
  }

  return (
    <>
      <Modal open={openModal}>
        <div>
          <ModalContent
            title="Informacija"
            description="Odabir omiljene usluge omogućeje Vam brži način rezervacije termina. Prilikom rezervacije termina omiljena usluga će biti postavljena kao početno odabrana, te je nepotrebno  izvršiti odabir usluge. Također na početnoj stranici, prikaz najbližeg slobodnog termina će prikazivati najbliži termin prema vašoj omiljenoj usluzi."
            iconColor="action.disabled"
            icon={InfoRoundedIcon}
            children={
              <AppButton variant="contained" size="large" fullWidth onClick={handleModal}>
                Zatvori
              </AppButton>
            }
          />
        </div>
      </Modal>
      <AppHeader />
      <AppLabel text="Omiljena usluga" />
      {selectedService ? (
        <AppFavourite
          key={`service.id-${selectedService.id}`}
          name={selectedService.name}
          description={selectedService.description}
          favourite
          onClick={() => removeService()}
        />
      ) : (
        <Box bgcolor={'secondary.main'} p={3}>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography fontSize={15} fontWeight={500} color={'info.main'}>
              Nemate omiljenu uslugu
            </Typography>
            <InfoRoundedIcon sx={{ color: 'info.main' }} onClick={() => setOpenModal(true)} />
          </Stack>
        </Box>
      )}
      <AppLabel text="Usluge" />
      <Stack gap={1}>
        {data?.services
          .filter((s) => s.id !== userData?.favouriteServiceId && s.bookable)
          .map((service) => (
            <AppFavourite
              key={`service.id-${service.id}`}
              name={service.name}
              description={service.description!}
              onClick={() => selectService(service.id)}
            />
          ))}
      </Stack>
    </>
  );
};
