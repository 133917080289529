import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC } from 'react';

const SnackbarCloseButton: FC<{ snackbarKey: SnackbarKey }> = function ({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon />
    </IconButton>
  );
};

export default SnackbarCloseButton;
