import { Skeleton, Stack, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { AppScreenInfo } from '~/components/AppScreenInfo/AppScreenInfo';
import { AppointmentSummary } from '~/components/AppointmentSummary/AppointmentSummary';
import { useHomeScreenQuery } from '~/graphql/generated';
import { IBookingLocationState } from './Booking/BookingContextProvider';
import { AuthContext } from '~/contexts/AuthContext';

export const Home: FC = () => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [now] = useState(new Date());

  const response = useHomeScreenQuery({
    variables: {
      date: now,
      take: 3,
    },
  });

  const futureAppointmentsLength = response.data?.appointments.length || 0;
  const hasFutureAppointments = response.data?.appointments.length;

  if (response.loading) {
    return (
      <Stack padding={2} gap={1}>
        <Skeleton variant="text" width={70} height={40} />
        <Skeleton variant="rounded" width={'100%'} height={60} />
        <Skeleton variant="text" width={70} height={40} />
        <Skeleton variant="rounded" width={'100%'} height={60} />
      </Stack>
    );
  }

  const maxBookingsExceeded = import.meta.env.VITE_USER_BOOKING_MAX_APPOINTMENTS <= futureAppointmentsLength;

  return (
    <>
      <AppLabel text="Vaši najbliži nadolazeći termini" />
      {!hasFutureAppointments ? (
        <AppScreenInfo text="Nemate rezerviranih termina" />
      ) : (
        <Stack gap={0.625} alignItems={'center'}>
          {response.data?.appointments.map((appointment) => (
            <AppointmentSummary
              key={`${appointment.id}-appointment`}
              button={{
                text: 'Pregled',
                props: {
                  variant: 'outlined',
                  onClick: () => {
                    navigate(`/appointments/${appointment.id}`);
                  },
                },
              }}
              start={appointment.start}
              end={appointment.end}
              serviceName={appointment.service.name}
              duration={appointment.service.duration}
              price={appointment.service.price}
              barberFullName={appointment.barber.name}
            />
          ))}
        </Stack>
      )}

      <AppLabel text="najbliži slobodan termin po frizeru" />
      <Stack gap={0.625} alignItems={'center'}>
        {response.loading && <Typography variant="body1">Loading...</Typography>}
        {response.data?.closestAvailableTimeSlots.map((timeSlot) => {
          return (
            <AppointmentSummary
              key={`${timeSlot.timeSlots[0].start}-closestAvailableTimeSlot-barber-${timeSlot.barber.id}`}
              disabled={context.userData?.blocked || maxBookingsExceeded}
              button={{
                text: 'Rezerviraj',
                props: {
                  onClick: () => {
                    navigate('/booking/review', {
                      state: {
                        barber: timeSlot.barber,
                        service: timeSlot.service,
                        selectedTime: {
                          date: timeSlot.date,
                          start: timeSlot.timeSlots[0].start,
                          end: timeSlot.timeSlots[0].end,
                        },
                      } as IBookingLocationState,
                    });
                  },
                  variant: 'contained',
                },
              }}
              start={timeSlot.timeSlots[0].start}
              end={timeSlot.timeSlots[0].end}
              serviceName={timeSlot.service.name}
              duration={timeSlot.service.duration}
              price={timeSlot.service.price}
              barberFullName={timeSlot.barber.name}
            />
          );
        })}
      </Stack>
    </>
  );
};
