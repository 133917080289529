import { FC } from 'react';
import { Stack, Typography, SvgIcon } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export interface IAppMenuItemProp {
  text: string;
  icon: typeof SvgIcon;
  onClick: () => void;
}

export const AppMenuItem: FC<IAppMenuItemProp> = (props) => {
  return (
    <Stack
      onClick={props.onClick}
      flexDirection="row"
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={25}
      padding={1.5}
      sx={{ backgroundColor: 'secondary.dark' }}
      width={'100%'}
      gap={2}
    >
      {<props.icon sx={{ color: 'action.disabled' }} />}
      <Stack flexDirection={'row'} alignItems={'center'} flex={1} justifyContent={'space-between'} gap={1.25}>
        <Typography textTransform={'none'} fontWeight={500} fontSize={15} pt={'2.5px'}>
          {props.text}
        </Typography>
        <KeyboardArrowRightIcon color="primary" />
      </Stack>
    </Stack>
  );
};
