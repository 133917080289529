import { Modal, Stack } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppointmentSummary } from '~/components/AppointmentSummary/AppointmentSummary';
import { ClapIcon } from '~/components/Icons/ClapIcon';
import { ModalContent } from '~/components/ModalContent/ModalContent';
import { useBookAppointmentMutation } from '~/graphql/generated';
import { BookingContext } from './useBookingContext';
import { AuthContext } from '~/contexts/AuthContext';

export const BookingReview: FC = () => {
  const navigate = useNavigate();
  const context = useContext(BookingContext);
  const { userData } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [book, { loading }] = useBookAppointmentMutation({
    onCompleted(data) {
      if (data.bookAppointment.id) {
        setOpenModal(true);
      }
    },
  });

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          navigate('/');
        }}
      >
        <div>
          <ModalContent
            title="Čestitamo"
            description="Uspješno ste rezervirali našu uslugu. Vidimo se uskoro"
            icon={ClapIcon}
          >
            <AppButton
              fullWidth
              variant="contained"
              onClick={() => {
                setOpenModal(false);
                navigate('/');
              }}
            >
              Natrag na početnu
            </AppButton>
          </ModalContent>
        </div>
      </Modal>
      <AppHeader title="Pregled i potvrda" />
      <Stack gap={0.625} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
        {context.selectedTime?.start && context.selectedTime?.end && (
          <>
            <AppointmentSummary
              review
              start={context.selectedTime?.start}
              end={context.selectedTime?.end}
              serviceName={context.service?.name || ''}
              duration={context.service?.duration || 0}
              price={context.service?.price || 0}
              barberFullName={context.barber?.name || ''}
              serviceDescription={context.service?.description}
            />

            <AppButton
              variant="contained"
              sx={{ width: '80%' }}
              disabled={userData?.blocked}
              onClick={() => {
                if (context.barber?.id && context.service?.id) {
                  book({
                    variables: {
                      data: {
                        barberId: context.barber?.id,
                        serviceId: context.service?.id,
                        date: context.selectedTime?.date,
                        start: context.selectedTime?.start,
                        end: context.selectedTime?.end,
                      },
                    },
                  });
                }
              }}
              loading={loading}
            >
              potvrdi rezervaciju
            </AppButton>
          </>
        )}
      </Stack>
    </>
  );
};
