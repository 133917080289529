import { Container, Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppHeader } from './AppHeader/AppHeader';
import { Stepper } from './Stepper/Stepper';

export const AuthenticationChildrenContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        width: '100%',
      }}
      gap={10}
    >
      <Stack gap={4}>
        <AppHeader onClick={() => navigate(-1)} />
        <Stepper
          steps={[
            { value: '1', label: location?.pathname.includes('login') ? 'Login' : 'Registracija' },
            { value: '2', label: 'Verifikacija' },
          ]}
          activeStep={location?.pathname.includes('verification') ? 1 : 0}
        />
      </Stack>
      <Container
        sx={{
          height: '100%',
          paddingBottom: 3.5,
        }}
      >
        <Outlet />
      </Container>
    </Stack>
  );
};
