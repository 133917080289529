import { type FactoryOpts } from 'imask';

import parsePhoneNumber from 'libphonenumber-js/mobile';
import { useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { useIMask } from 'react-imask';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePhoneHook = (control: Control<any>) => {
  const phoneField = useController({ control, name: 'phone' });

  const mask = useIMask<HTMLInputElement, FactoryOpts>({
    mask: [{ mask: '{000} 000 000' }, { mask: '{000} 000 0000' }, { mask: '+385000000000' }, { mask: '+38500000000' }],
    lazy: true,
  });

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(mask.unmaskedValue, 'HR');

    phoneField.field.onChange({
      target: { name: phoneField.field.name, value: phoneNumber?.number },
    });
  }, [mask.ref.current?.value, mask.unmaskedValue, phoneField.field]);

  return {
    phoneField,
    mask,
  };
};
