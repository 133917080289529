import { Box, Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import Logo from '~/assets/img/zlatni-cesalj-logo-sa-tekstom.webp';
import { AppButton } from '~/components/AppButton';

export const AutheticationOptions = () => {
  const navigate = useNavigate();
  return (
    <Stack
      display={'flex'}
      flex={1}
      direction="column"
      justifyContent={'space-between'}
      alignItems={'center'}
      maxWidth={'100%'}
      maxHeight={'100%'}
      px={1.5}
      py={7}
      gap={1.5}
    >
      <Box
        component={'img'}
        src={Logo}
        width={'100%'}
        height="auto"
        maxWidth={'25rem'}
        sx={{
          aspectRatio: '600/479',
        }}
        alt="Zlatni češalj logo"
      ></Box>

      <Stack gap={3} width={'100%'}>
        <AppButton variant="contained" size="large" onClick={() => navigate('/authentication/login')}>
          Prijava
        </AppButton>
        <AppButton variant="outlined" size="large" onClick={() => navigate('/authentication/register')}>
          Registracija
        </AppButton>
      </Stack>
    </Stack>
  );
};
