import { Alert, Stack } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppBottomNavigation } from './AppBottomNavigation/AppBottomNavigation';
import { useContext, useEffect } from 'react';
import { AuthContext } from '~/contexts/AuthContext';

export const AuthenticatedContainer = () => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!context.mounting) {
      if (!context.isLoggedIn) {
        navigate('/authentication/options', {
          replace: true,
        });
      }
    }
  }, [context, navigate]);

  return (
    <Stack height="100vh" width="100%">
      {context.userData?.blocked && <Alert severity="error">Vaš račun je blokiran!</Alert>}
      <Stack flex={1} overflow={'auto'}>
        <Stack flex={1} pb={3} position={'relative'}>
          <Outlet />
        </Stack>
      </Stack>
      <AppBottomNavigation />
    </Stack>
  );
};
