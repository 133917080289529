import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#2C2B29',
      paper: '#2C2B29',
    },
    primary: {
      dark: '#D9C69E',
      main: '#D9C69E',
    },
    secondary: {
      dark: '#323232',
      main: '#323232',
    },
    info: {
      main: '#948C84',
    },
    error: {
      main: '#bd2626',
    },
    success: {
      main: '#10A44C',
    },
    action: {
      disabled: '#67686D',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold',
          borderRadius: 25,
          paddingLeft: 30,
          paddingRight: 30,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          '.MuiFormHelperText-root.Mui-error ': {
            // padding: props.theme.spacing(1, 0.5),
            // background: '#312a2a',
            // border: '1px solid #C55050',
            // borderRadius: props.theme.spacing(1),
            // marginLeft: 0,
            // marginRight: 0,
            color: '#E58A8A',
          },
        },
      },
    },

    MuiBottomNavigationAction: {
      styleOverrides: {
        root: (props) => ({
          fontSize: '0.75rem',
          fontWeight: 500,
          color: '#67686D',
          gap: props.theme.spacing(1),
        }),
      },
    },

    MuiBottomNavigation: {
      styleOverrides: {
        root: (props) => ({
          backgroundColor: props.theme.palette.secondary.main,
          borderTop: `1px solid ${props.theme.palette.background.default}`,
          height: '76px',
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '15px !important',
          background: '#f5f5f5',
          color: '#000',
        },
        input: (props) => {
          return {
            ...(props.ownerState.size === 'medium' ? { padding: '11.5px 14px !important' } : null),
          };
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: (props) => ({
          width: 'fit-content',
          borderRadius: 10,
          ':hover': {
            backgroundColor: props.theme.palette.primary.main,
          },
        }),
        outlined: () => ({
          border: '1px solid #f5f5f5',
        }),
        filled: (props) => ({
          backgroundColor: props.theme.palette.primary.main,
          fontWeight: 600,
        }),
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: (props) => ({
          width: '100%',
          bottom: '75px !important',
          left: '0 !important',
          background: props.theme.palette.background.default,
          borderRadius: '0 !important',
        }),
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: (props) => ({
          maxWidth: '800px',
          width: '100%',
          bottom: '75px !important',
          background: props.theme.palette.background.default,
          borderRadius: '0 !important',
          [`.MuiPaper-root`]: {
            boxShadow: 'none',
            flexWrap: 'unset',
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: () => ({
          width: 53,
          height: 24,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2.5,
            transitionDuration: '300ms',
            color: '#FFFFFF',
            '&.Mui-checked': {
              transform: 'translateX(29px)',
              color: '#FFFFFF',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.success.main,
                opacity: 1,
                border: 0,
                color: '#FFFFFF',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: theme.palette.success.main,
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#FFFFFF',
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18.5,
            height: 18.5,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#EFEFEF',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        }),
      },
    },
  },
});
