import { useMutation, useQuery } from '@tanstack/react-query';
import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { firebaseAuth } from '~/config/firebase';

export const useUpdatePhoneSendCode = (phoneNumber: string, onSuccess?: () => void, onError?: () => void) => {
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
  const [provider, setProvider] = useState<PhoneAuthProvider>();
  const [verificationId, setVerificationId] = useState<string>();

  useEffect(() => {
    const verifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      firebaseAuth,
    );

    if (!recaptchaVerifier) {
      verifier
        .verify()
        .then(() => setRecaptchaVerifier(verifier))
        .catch(() => {
          //
        });
    }

    const provider = new PhoneAuthProvider(firebaseAuth);
    setProvider(provider);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendVerifyCode = useCallback(async () => {
    if (!recaptchaVerifier) return null;
    return await provider?.verifyPhoneNumber(phoneNumber, recaptchaVerifier);
  }, [phoneNumber, provider, recaptchaVerifier]);

  const sendVerifyCodeFn = useQuery(['send-otp'], sendVerifyCode, {
    onSuccess: (id: string) => {
      setVerificationId(id);
    },
    onError,
    enabled: !!phoneNumber && !!recaptchaVerifier && !!provider,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const updatePhoneNumberFn = useMutation({
    mutationFn: async (verificationCode: string) => {
      if (!firebaseAuth.currentUser || !verificationId) return null;

      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
      return updatePhoneNumber(firebaseAuth.currentUser, phoneCredential);
    },
    onSuccess,
    onError,
  });

  return {
    updatePhoneNumberFn,
    sendVerifyCodeFn,
  };
};
