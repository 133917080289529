import { useMutation, useQuery } from '@tanstack/react-query';
import { ConfirmationResult, RecaptchaVerifier, UserCredential, signInWithPhoneNumber } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { firebaseAuth } from '~/config/firebase';

export const useSignInWithPhoneNumber = (
  phoneNumber: string,
  onSuccess?: (data: UserCredential | null) => void,
  onError?: (error: unknown) => void,
) => {
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
  const [confirmResult, setConfirmResult] = useState<ConfirmationResult>();

  useEffect(() => {
    const verifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      firebaseAuth,
    );

    if (!recaptchaVerifier) {
      verifier
        .verify()
        .then(() => setRecaptchaVerifier(verifier))
        .catch(() => {
          //
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendVerifyCode = useCallback(async () => {
    if (!recaptchaVerifier) return;

    return signInWithPhoneNumber(firebaseAuth, phoneNumber, recaptchaVerifier);
  }, [phoneNumber, recaptchaVerifier]);

  const sendVerifyCodeQuery = useQuery(['send-otp'], sendVerifyCode, {
    onSuccess: (result) => {
      if (result) {
        setConfirmResult(result);
      }
    },
    enabled: !!phoneNumber && !!recaptchaVerifier,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // refetchOnReconnect: false,
    // retry: false,
  });

  const signInWithPhoneNumberMutation = useMutation({
    mutationFn: async (code: string) => {
      return confirmResult?.confirm!(code) || null;
    },
    onSuccess: onSuccess,
    onError: onError,
  });

  return {
    sendVerifyCodeQuery,
    signInWithPhoneNumberMutation,
  };
};
