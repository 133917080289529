import { Stack, Switch, Typography } from '@mui/material';
import { FC, useState } from 'react';

export interface IAppSwitch {
  text?: string;
  disabled?: boolean;
  checked: boolean;
}

export const AppSwitch: FC<IAppSwitch> = (props) => {
  const [switchOn, setSwitchOn] = useState(props.checked);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchOn(event.target.checked);
  };

  return (
    <Stack bgcolor={'secondary.main'} p={3} gap={3}>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography fontSize={16} fontWeight={500} color={props.disabled ? 'action.disabled' : ' #F5F5F5'}>
          {switchOn ? 'Ukljuceno' : 'Iskljuceno'}
        </Typography>
        <Switch
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          onChange={handleSwitchChange}
          checked={switchOn}
          disabled={props.disabled}
        />
      </Stack>
      {props.disabled && (
        <Typography fontSize={16} fontWeight={500} sx={{ color: 'action.disabled' }}>
          {props.text}
        </Typography>
      )}
    </Stack>
  );
};
