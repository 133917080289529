import { FirebaseError } from 'firebase/app';
import { User, onAuthStateChanged } from 'firebase/auth';
import { enqueueSnackbar } from 'notistack';
import { FC, createContext, useEffect, useMemo, useState } from 'react';
import { firebaseAuth } from '~/config/firebase';
import { MeDataQuery, useMeDataLazyQuery } from '~/graphql/generated';
import { getFirebaseErrorsDescription } from '~/helpers/utils';

export const AuthContext = createContext<{
  user: User | null;
  mounting: boolean;
  isLoggedIn: boolean;
  logoutUser: () => void;
  userData: MeDataQuery['user'] | undefined;
  setUserProfile: (user: MeDataQuery['user']) => void;
}>({
  isLoggedIn: false,
  logoutUser: () => {
    //
  },
  mounting: true,
  user: null,
  userData: undefined,
  setUserProfile: () => {
    //
  },
});

export const AuthProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<MeDataQuery['user']>();
  const [mounting, setMounting] = useState(true);

  const [fetchMeData, { data: meData }] = useMeDataLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.user) {
        setUserData(data.user);
      }
      setMounting(false);
    },
    onError() {
      setMounting(false);
      return 'null';
    },
  });

  useEffect(() => {
    setUserData(meData?.user);
  }, [meData?.user]);

  useEffect(() => {
    if (mounting && user) {
      fetchMeData();
    }
  }, [fetchMeData, mounting, user]);

  useEffect(() => {
    onAuthStateChanged(
      firebaseAuth,
      (user: User | null) => {
        if (user) {
          setUser(user);
        } else {
          setMounting(false);
        }
      },
      (error: Error | FirebaseError) => {
        setMounting(false);

        let errorMessage = 'Nepoznata greska';

        if (error instanceof FirebaseError) {
          errorMessage = getFirebaseErrorsDescription(error.code);
          if (error.code === 'auth/popup-closed-by-user') {
            setMounting(false);
          } else if (error.code === 'auth/user-disabled') {
            setMounting(false);
          }
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: 3000,
        });
      },
    );
  }, [fetchMeData, mounting]);

  const setUserProfile = (user: MeDataQuery['user']) => {
    setUserData(user);
  };

  const logoutUser = () => {
    firebaseAuth.signOut().then(() => {
      setUser(null);
      setUserData(undefined);
    });
  };

  const isLoggedIn = useMemo(() => !!(user && userData), [user, userData]);

  const value = useMemo(
    () => ({ isLoggedIn, logoutUser, mounting: mounting, user, userData, setUserProfile }),
    [user, mounting, userData, isLoggedIn],
  );

  return <AuthContext.Provider value={value}>{mounting ? null : children}</AuthContext.Provider>;
};
