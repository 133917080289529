import { Box, BoxProps, useTheme } from '@mui/material';
import { FC } from 'react';

export const AppContainer: FC<BoxProps> = (props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: 1,
        background: theme.palette.background.default,
        display: 'flex',
      }}
    >
      {props.children}
    </Box>
  );
};
