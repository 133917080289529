import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { enqueueSnackbar } from 'notistack';
import { getBackendErrorsDescription } from '~/helpers/utils';
import { firebaseAuth } from './firebase';

const httpLink = new HttpLink({ uri: import.meta.env.VITE_BACKEND_URL });

const authLink = setContext(async (_, { headers }) => {
  const token = await firebaseAuth.currentUser?.getIdToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

//log query errors
const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors?.length) {
    graphQLErrors.map((error) => {
      const message = getBackendErrorsDescription(error);
      enqueueSnackbar(message, {
        variant: 'warning',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 5000,
      });
    });
  }

  if (networkError) {
    enqueueSnackbar('Dogodila se pogreška!', {
      variant: 'error',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      autoHideDuration: 5000,
    });
  }
});

const timeoutMiddleware = new ApolloLink((operation, forward) => {
  // Set a timeout of 5 seconds
  const timeout = 10000;
  // Create a timeoutId variable
  let timeoutId: string | number | NodeJS.Timeout | undefined;
  // Return a new observable that will timeout if the query takes too long
  return new Observable((observer) => {
    // Start the query
    const subscription = forward(operation).subscribe({
      complete: observer.complete.bind(observer),
      error: observer.error.bind(observer),
      next: observer.next.bind(observer),
    });
    // Set the timeout
    timeoutId = setTimeout(() => {
      // If the query takes too long, unsubscribe from the query
      subscription.unsubscribe();
      // Send an error to the observer
      observer.error(new Error('Timeout Error'));
    }, timeout);
    // Return a cleanup function that will clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  });
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          user: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    mutate: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
  link: ApolloLink.from([errorLink, timeoutMiddleware, authLink.concat(httpLink)]),
});
