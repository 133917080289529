import { Stack, StandardTextFieldProps, TextField, TextFieldProps, Typography } from '@mui/material';
import { forwardRef } from 'react';

export interface IAppInputProps {
  label: string;
  value: string;
  onChange: StandardTextFieldProps['onChange'];
  textFieldProps?: TextFieldProps;
  onBlur?: () => void;
  name?: string;
  errorMessage?: string;
}

export const AppInput = forwardRef<HTMLInputElement, IAppInputProps>((props, ref) => {
  return (
    <Stack gap={0.6}>
      <Typography variant="caption" fontWeight="600">
        {props.label}
      </Typography>
      <TextField
        inputRef={ref}
        name={props.name}
        fullWidth
        defaultValue={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        {...props.textFieldProps}
      />
    </Stack>
  );
});
