/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
  Decimal: { input: any; output: any; }
};

export type AdditionalServiceNullableRelationFilter = {
  is?: InputMaybe<AdditionalServiceWhereInput>;
  isNot?: InputMaybe<AdditionalServiceWhereInput>;
};

export type AdditionalServiceVersionListRelationFilter = {
  every?: InputMaybe<AdditionalServiceVersionWhereInput>;
  none?: InputMaybe<AdditionalServiceVersionWhereInput>;
  some?: InputMaybe<AdditionalServiceVersionWhereInput>;
};

export type AdditionalServiceVersionWhereInput = {
  AND?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  additionalService?: InputMaybe<AdditionalServiceNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  versionAdditionalServiceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AdditionalServiceWhereInput = {
  AND?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<IntFilter>;
};

export type AppointmentListRelationFilter = {
  every?: InputMaybe<AppointmentWhereInput>;
  none?: InputMaybe<AppointmentWhereInput>;
  some?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentNullableRelationFilter = {
  is?: InputMaybe<AppointmentWhereInput>;
  isNot?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentVersionListRelationFilter = {
  every?: InputMaybe<AppointmentVersionWhereInput>;
  none?: InputMaybe<AppointmentVersionWhereInput>;
  some?: InputMaybe<AppointmentVersionWhereInput>;
};

export type AppointmentVersionWhereInput = {
  AND?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  OR?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  appointment?: InputMaybe<AppointmentNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalNullableFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionAppointmentId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AppointmentWhereInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type AppointmentWhereUniqueInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export enum AuditOperation {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

export type AvailableTimeSlot = IAvailableTimeSlot & {
  __typename?: 'AvailableTimeSlot';
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  date: Scalars['DateTimeISO']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
  timeSlots: Array<TimeSlot>;
};

export type AvailableTimeSlotsInput = {
  barberId: Scalars['Int']['input'];
  dates: Array<Scalars['DateTimeISO']['input']>;
  serviceId: Scalars['Int']['input'];
};

export type BarberNullableRelationFilter = {
  is?: InputMaybe<BarberWhereInput>;
  isNot?: InputMaybe<BarberWhereInput>;
};

export type BarberRelationFilter = {
  is?: InputMaybe<BarberWhereInput>;
  isNot?: InputMaybe<BarberWhereInput>;
};

export type BarberServiceListRelationFilter = {
  every?: InputMaybe<BarberServiceWhereInput>;
  none?: InputMaybe<BarberServiceWhereInput>;
  some?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceNullableRelationFilter = {
  is?: InputMaybe<BarberServiceWhereInput>;
  isNot?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceVersionListRelationFilter = {
  every?: InputMaybe<BarberServiceVersionWhereInput>;
  none?: InputMaybe<BarberServiceVersionWhereInput>;
  some?: InputMaybe<BarberServiceVersionWhereInput>;
};

export type BarberServiceVersionWhereInput = {
  AND?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberService?: InputMaybe<BarberServiceNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberServiceId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberServiceWhereInput = {
  AND?: InputMaybe<Array<BarberServiceWhereInput>>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberServiceWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
};

export enum BarberType {
  Admin = 'ADMIN',
  Normal = 'NORMAL'
}

export type BarberVersionListRelationFilter = {
  every?: InputMaybe<BarberVersionWhereInput>;
  none?: InputMaybe<BarberVersionWhereInput>;
  some?: InputMaybe<BarberVersionWhereInput>;
};

export type BarberVersionWhereInput = {
  AND?: InputMaybe<Array<BarberVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barber?: InputMaybe<BarberNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBarberTypeNullableFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWhereInput = {
  AND?: InputMaybe<Array<BarberWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  BarberEditorVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  BarberVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  Client?: InputMaybe<ClientListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWhereInput>>;
  OR?: InputMaybe<Array<BarberWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideListRelationFilter>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumBarberTypeFilter>;
  uid?: InputMaybe<StringFilter>;
};

export type BarberWorkTimeListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeWhereInput>;
  none?: InputMaybe<BarberWorkTimeWhereInput>;
  some?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeNullableRelationFilter = {
  is?: InputMaybe<BarberWorkTimeWhereInput>;
  isNot?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeOverrideListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  none?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  some?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideNullableRelationFilter = {
  is?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  isNot?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideVersionListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
  none?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
  some?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};

export type BarberWorkTimeOverrideVersionWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideNullableRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeOverrideId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeOverrideWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
};

export type BarberWorkTimeVersionListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeVersionWhereInput>;
  none?: InputMaybe<BarberWorkTimeVersionWhereInput>;
  some?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};

export type BarberWorkTimeVersionWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberWorkTime?: InputMaybe<BarberWorkTimeNullableRelationFilter>;
  dayOfWeek?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  dayOfWeek?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type BookAppointmentInput = {
  barberId: Scalars['Int']['input'];
  date: Scalars['DateTimeISO']['input'];
  end: Scalars['DateTimeISO']['input'];
  serviceId: Scalars['Int']['input'];
  start: Scalars['DateTimeISO']['input'];
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type ClientAppointment = {
  __typename?: 'ClientAppointment';
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  clientId: Scalars['Int']['output'];
  end: Scalars['DateTimeISO']['output'];
  id: Scalars['Int']['output'];
  price: Scalars['Decimal']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
  start: Scalars['DateTimeISO']['output'];
};

export type ClientAppointmentOrderByInput = {
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type ClientAppointmentWhereInput = {
  AND?: InputMaybe<Array<ClientAppointmentWhereInput>>;
  NOT?: InputMaybe<Array<ClientAppointmentWhereInput>>;
  OR?: InputMaybe<Array<ClientAppointmentWhereInput>>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type ClientBarber = {
  __typename?: 'ClientBarber';
  barberServices?: Maybe<Array<ClientBarberService>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ClientBarberService = {
  __typename?: 'ClientBarberService';
  assignedAt: Scalars['DateTimeISO']['output'];
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
};

export type ClientClient = {
  __typename?: 'ClientClient';
  blocked: Scalars['Boolean']['output'];
  favouriteBarber?: Maybe<ClientBarber>;
  favouriteBarberId?: Maybe<Scalars['Int']['output']>;
  favouriteService?: Maybe<ClientService>;
  favouriteServiceId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  referralCode?: Maybe<Scalars['String']['output']>;
  smsNotification?: Maybe<Scalars['Boolean']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type ClientListRelationFilter = {
  every?: InputMaybe<ClientWhereInput>;
  none?: InputMaybe<ClientWhereInput>;
  some?: InputMaybe<ClientWhereInput>;
};

export type ClientNullableRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export type ClientRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export type ClientService = {
  __typename?: 'ClientService';
  _count?: Maybe<ServiceCount>;
  barberServices?: Maybe<Array<ClientBarberService>>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type ClientVersionListRelationFilter = {
  every?: InputMaybe<ClientVersionWhereInput>;
  none?: InputMaybe<ClientVersionWhereInput>;
  some?: InputMaybe<ClientVersionWhereInput>;
};

export type ClientVersionWhereInput = {
  AND?: InputMaybe<Array<ClientVersionWhereInput>>;
  NOT?: InputMaybe<Array<ClientVersionWhereInput>>;
  OR?: InputMaybe<Array<ClientVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolNullableFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionClientId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ClientWhereInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  blocked?: InputMaybe<BoolFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  clientEditorVersion?: InputMaybe<ClientVersionListRelationFilter>;
  favouriteBarber?: InputMaybe<BarberNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteService?: InputMaybe<ServiceNullableRelationFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  penalties?: InputMaybe<PenaltyListRelationFilter>;
  phone?: InputMaybe<StringFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolFilter>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type EnumAuditOperationFilter = {
  equals?: InputMaybe<AuditOperation>;
  in?: InputMaybe<Array<AuditOperation>>;
  not?: InputMaybe<NestedEnumAuditOperationFilter>;
  notIn?: InputMaybe<Array<AuditOperation>>;
};

export type EnumBarberTypeFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type EnumBarberTypeNullableFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeNullableFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type IAvailableTimeSlot = {
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  date: Scalars['DateTimeISO']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
  timeSlots: Array<TimeSlot>;
};

export type ITimeSlot = {
  end: Scalars['DateTimeISO']['output'];
  start: Scalars['DateTimeISO']['output'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bookAppointment: ClientAppointment;
  cancelAppointment?: Maybe<ClientAppointment>;
  register: ClientClient;
  updateProfile: ClientClient;
};


export type MutationBookAppointmentArgs = {
  data: BookAppointmentInput;
};


export type MutationCancelAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedEnumAuditOperationFilter = {
  equals?: InputMaybe<AuditOperation>;
  in?: InputMaybe<Array<AuditOperation>>;
  not?: InputMaybe<NestedEnumAuditOperationFilter>;
  notIn?: InputMaybe<Array<AuditOperation>>;
};

export type NestedEnumBarberTypeFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type NestedEnumBarberTypeNullableFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeNullableFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type PenaltyListRelationFilter = {
  every?: InputMaybe<PenaltyWhereInput>;
  none?: InputMaybe<PenaltyWhereInput>;
  some?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyNullableRelationFilter = {
  is?: InputMaybe<PenaltyWhereInput>;
  isNot?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyVersionListRelationFilter = {
  every?: InputMaybe<PenaltyVersionWhereInput>;
  none?: InputMaybe<PenaltyVersionWhereInput>;
  some?: InputMaybe<PenaltyVersionWhereInput>;
};

export type PenaltyVersionWhereInput = {
  AND?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  OR?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  penalty?: InputMaybe<PenaltyNullableRelationFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionPenaltyId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type PenaltyWhereInput = {
  AND?: InputMaybe<Array<PenaltyWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyWhereInput>>;
  OR?: InputMaybe<Array<PenaltyWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  reason?: InputMaybe<StringFilter>;
};

export type Query = {
  __typename?: 'Query';
  appointments: Array<ClientAppointment>;
  availableTimeSlots: Array<AvailableTimeSlot>;
  barbers: Array<ClientBarber>;
  closestAvailableTimeSlots: Array<AvailableTimeSlot>;
  phoneExists: Scalars['Boolean']['output'];
  services: Array<ClientService>;
  user?: Maybe<ClientClient>;
};


export type QueryAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ClientAppointmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientAppointmentWhereInput>;
};


export type QueryAvailableTimeSlotsArgs = {
  data: AvailableTimeSlotsInput;
};


export type QueryPhoneExistsArgs = {
  phone: Scalars['String']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RegisterInput = {
  /** The Firebase ID Token */
  idToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  refererCode?: InputMaybe<Scalars['String']['input']>;
  tac: Scalars['Boolean']['input'];
  userUID: Scalars['String']['input'];
};

export type ServiceCount = {
  __typename?: 'ServiceCount';
  Client: Scalars['Int']['output'];
  ServiceVersion: Scalars['Int']['output'];
  appointments: Scalars['Int']['output'];
  barberServices: Scalars['Int']['output'];
};


export type ServiceCountClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type ServiceCountServiceVersionArgs = {
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type ServiceCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ServiceCountBarberServicesArgs = {
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type ServiceNullableRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export type ServiceRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export type ServiceVersionListRelationFilter = {
  every?: InputMaybe<ServiceVersionWhereInput>;
  none?: InputMaybe<ServiceVersionWhereInput>;
  some?: InputMaybe<ServiceVersionWhereInput>;
};

export type ServiceVersionWhereInput = {
  AND?: InputMaybe<Array<ServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<ServiceVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  bookable?: InputMaybe<BoolNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionServiceId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  Client?: InputMaybe<ClientListRelationFilter>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  bookable?: InputMaybe<BoolFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TimeSlot = ITimeSlot & {
  __typename?: 'TimeSlot';
  end: Scalars['DateTimeISO']['output'];
  start: Scalars['DateTimeISO']['output'];
};

export type UpdateProfileInput = {
  favouriteBarberId?: InputMaybe<Scalars['Int']['input']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type BookAppointmentMutationVariables = Exact<{
  data: BookAppointmentInput;
}>;


export type BookAppointmentMutation = { __typename?: 'Mutation', bookAppointment: { __typename?: 'ClientAppointment', id: number } };

export type CancelAppointmentMutationVariables = Exact<{
  where: AppointmentWhereUniqueInput;
}>;


export type CancelAppointmentMutation = { __typename?: 'Mutation', cancelAppointment?: { __typename?: 'ClientAppointment', id: number } | null };

export type RegisterMutationVariables = Exact<{
  data: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'ClientClient', id: number, name: string, phone: string, verified: boolean, blocked: boolean } };

export type UpdateProfileMutationVariables = Exact<{
  data: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'ClientClient', id: number, name: string, phone: string, blocked: boolean, favouriteServiceId?: number | null, favouriteBarberId?: number | null, favouriteService?: { __typename?: 'ClientService', id: number, name: string, description?: string | null } | null, favouriteBarber?: { __typename?: 'ClientBarber', id: number, name: string } | null } };

export type AppointmentsQueryVariables = Exact<{
  where?: InputMaybe<ClientAppointmentWhereInput>;
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AppointmentsQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };

export type AvailableTerminsQueryVariables = Exact<{
  data: AvailableTimeSlotsInput;
}>;


export type AvailableTerminsQuery = { __typename?: 'Query', availableTimeSlots: Array<{ __typename?: 'AvailableTimeSlot', date: any, barberId: number, serviceId: number, timeSlots: Array<{ __typename?: 'TimeSlot', start: any, end: any }> }> };

export type BarbersQueryVariables = Exact<{ [key: string]: never; }>;


export type BarbersQuery = { __typename?: 'Query', barbers: Array<{ __typename?: 'ClientBarber', id: number, name: string }> };

export type ClosestAvailableTerminsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClosestAvailableTerminsQuery = { __typename?: 'Query', closestAvailableTimeSlots: Array<{ __typename?: 'AvailableTimeSlot', date: any, barberId: number, serviceId: number, timeSlots: Array<{ __typename?: 'TimeSlot', start: any, end: any }>, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };

export type ServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesQuery = { __typename?: 'Query', services: Array<{ __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number, bookable?: boolean | null, barberServices?: Array<{ __typename?: 'ClientBarberService', id: number, barberId: number, barber: { __typename?: 'ClientBarber', id: number, name: string } }> | null }> };

export type MeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type MeDataQuery = { __typename?: 'Query', user?: { __typename?: 'ClientClient', id: number, name: string, phone: string, blocked: boolean, favouriteServiceId?: number | null, favouriteBarberId?: number | null, referralCode?: string | null, smsNotification?: boolean | null, favouriteService?: { __typename?: 'ClientService', id: number, name: string, description?: string | null } | null, favouriteBarber?: { __typename?: 'ClientBarber', id: number, name: string } | null } | null };

export type PhoneExistsQueryVariables = Exact<{
  phone: Scalars['String']['input'];
}>;


export type PhoneExistsQuery = { __typename?: 'Query', phoneExists: boolean };

export type HomeScreenQueryVariables = Exact<{
  date: Scalars['DateTimeISO']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type HomeScreenQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }>, closestAvailableTimeSlots: Array<{ __typename?: 'AvailableTimeSlot', date: any, barberId: number, serviceId: number, timeSlots: Array<{ __typename?: 'TimeSlot', start: any, end: any }>, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };

export type TerminsScreenQueryVariables = Exact<{
  date: Scalars['DateTimeISO']['input'];
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TerminsScreenQuery = { __typename?: 'Query', futureAppointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }>, pastAppointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };


export const BookAppointmentDocument = gql`
    mutation bookAppointment($data: BookAppointmentInput!) {
  bookAppointment(data: $data) {
    id
  }
}
    `;
export type BookAppointmentMutationFn = Apollo.MutationFunction<BookAppointmentMutation, BookAppointmentMutationVariables>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBookAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<BookAppointmentMutation, BookAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAppointmentMutation, BookAppointmentMutationVariables>(BookAppointmentDocument, options);
      }
export type BookAppointmentMutationHookResult = ReturnType<typeof useBookAppointmentMutation>;
export type BookAppointmentMutationResult = Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<BookAppointmentMutation, BookAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation cancelAppointment($where: AppointmentWhereUniqueInput!) {
  cancelAppointment(where: $where) {
    id
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const RegisterDocument = gql`
    mutation register($data: RegisterInput!) {
  register(data: $data) {
    id
    name
    phone
    verified
    blocked
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($data: UpdateProfileInput!) {
  updateProfile(data: $data) {
    id
    name
    phone
    blocked
    favouriteService {
      id
      name
      description
    }
    favouriteBarber {
      id
      name
    }
    favouriteServiceId
    favouriteBarberId
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const AppointmentsDocument = gql`
    query Appointments($where: ClientAppointmentWhereInput, $cursor: AppointmentWhereUniqueInput, $take: Int, $skip: Int) {
  appointments(where: $where, cursor: $cursor, take: $take, skip: $skip) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
}
    `;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export const AvailableTerminsDocument = gql`
    query AvailableTermins($data: AvailableTimeSlotsInput!) {
  availableTimeSlots(data: $data) {
    date
    timeSlots {
      start
      end
    }
    barberId
    serviceId
  }
}
    `;

/**
 * __useAvailableTerminsQuery__
 *
 * To run a query within a React component, call `useAvailableTerminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTerminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTerminsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAvailableTerminsQuery(baseOptions: Apollo.QueryHookOptions<AvailableTerminsQuery, AvailableTerminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableTerminsQuery, AvailableTerminsQueryVariables>(AvailableTerminsDocument, options);
      }
export function useAvailableTerminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableTerminsQuery, AvailableTerminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableTerminsQuery, AvailableTerminsQueryVariables>(AvailableTerminsDocument, options);
        }
export type AvailableTerminsQueryHookResult = ReturnType<typeof useAvailableTerminsQuery>;
export type AvailableTerminsLazyQueryHookResult = ReturnType<typeof useAvailableTerminsLazyQuery>;
export type AvailableTerminsQueryResult = Apollo.QueryResult<AvailableTerminsQuery, AvailableTerminsQueryVariables>;
export const BarbersDocument = gql`
    query Barbers {
  barbers {
    id
    name
  }
}
    `;

/**
 * __useBarbersQuery__
 *
 * To run a query within a React component, call `useBarbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBarbersQuery(baseOptions?: Apollo.QueryHookOptions<BarbersQuery, BarbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarbersQuery, BarbersQueryVariables>(BarbersDocument, options);
      }
export function useBarbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarbersQuery, BarbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarbersQuery, BarbersQueryVariables>(BarbersDocument, options);
        }
export type BarbersQueryHookResult = ReturnType<typeof useBarbersQuery>;
export type BarbersLazyQueryHookResult = ReturnType<typeof useBarbersLazyQuery>;
export type BarbersQueryResult = Apollo.QueryResult<BarbersQuery, BarbersQueryVariables>;
export const ClosestAvailableTerminsDocument = gql`
    query closestAvailableTermins {
  closestAvailableTimeSlots {
    date
    timeSlots {
      start
      end
    }
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
  }
}
    `;

/**
 * __useClosestAvailableTerminsQuery__
 *
 * To run a query within a React component, call `useClosestAvailableTerminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosestAvailableTerminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosestAvailableTerminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClosestAvailableTerminsQuery(baseOptions?: Apollo.QueryHookOptions<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>(ClosestAvailableTerminsDocument, options);
      }
export function useClosestAvailableTerminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>(ClosestAvailableTerminsDocument, options);
        }
export type ClosestAvailableTerminsQueryHookResult = ReturnType<typeof useClosestAvailableTerminsQuery>;
export type ClosestAvailableTerminsLazyQueryHookResult = ReturnType<typeof useClosestAvailableTerminsLazyQuery>;
export type ClosestAvailableTerminsQueryResult = Apollo.QueryResult<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>;
export const ServicesDocument = gql`
    query Services {
  services {
    id
    name
    description
    duration
    price
    bookable
    barberServices {
      id
      barberId
      barber {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const MeDataDocument = gql`
    query MeData {
  user {
    id
    name
    phone
    blocked
    favouriteService {
      id
      name
      description
    }
    favouriteBarber {
      id
      name
    }
    favouriteServiceId
    favouriteBarberId
    referralCode
    smsNotification
  }
}
    `;

/**
 * __useMeDataQuery__
 *
 * To run a query within a React component, call `useMeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeDataQuery(baseOptions?: Apollo.QueryHookOptions<MeDataQuery, MeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeDataQuery, MeDataQueryVariables>(MeDataDocument, options);
      }
export function useMeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeDataQuery, MeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeDataQuery, MeDataQueryVariables>(MeDataDocument, options);
        }
export type MeDataQueryHookResult = ReturnType<typeof useMeDataQuery>;
export type MeDataLazyQueryHookResult = ReturnType<typeof useMeDataLazyQuery>;
export type MeDataQueryResult = Apollo.QueryResult<MeDataQuery, MeDataQueryVariables>;
export const PhoneExistsDocument = gql`
    query PhoneExists($phone: String!) {
  phoneExists(phone: $phone)
}
    `;

/**
 * __usePhoneExistsQuery__
 *
 * To run a query within a React component, call `usePhoneExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneExistsQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function usePhoneExistsQuery(baseOptions: Apollo.QueryHookOptions<PhoneExistsQuery, PhoneExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhoneExistsQuery, PhoneExistsQueryVariables>(PhoneExistsDocument, options);
      }
export function usePhoneExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhoneExistsQuery, PhoneExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhoneExistsQuery, PhoneExistsQueryVariables>(PhoneExistsDocument, options);
        }
export type PhoneExistsQueryHookResult = ReturnType<typeof usePhoneExistsQuery>;
export type PhoneExistsLazyQueryHookResult = ReturnType<typeof usePhoneExistsLazyQuery>;
export type PhoneExistsQueryResult = Apollo.QueryResult<PhoneExistsQuery, PhoneExistsQueryVariables>;
export const HomeScreenDocument = gql`
    query homeScreen($date: DateTimeISO!, $take: Int) {
  appointments(where: {start: {gt: $date}}, orderBy: {start: asc}, take: $take) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
  closestAvailableTimeSlots {
    date
    timeSlots {
      start
      end
    }
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
  }
}
    `;

/**
 * __useHomeScreenQuery__
 *
 * To run a query within a React component, call `useHomeScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeScreenQuery({
 *   variables: {
 *      date: // value for 'date'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useHomeScreenQuery(baseOptions: Apollo.QueryHookOptions<HomeScreenQuery, HomeScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeScreenQuery, HomeScreenQueryVariables>(HomeScreenDocument, options);
      }
export function useHomeScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeScreenQuery, HomeScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeScreenQuery, HomeScreenQueryVariables>(HomeScreenDocument, options);
        }
export type HomeScreenQueryHookResult = ReturnType<typeof useHomeScreenQuery>;
export type HomeScreenLazyQueryHookResult = ReturnType<typeof useHomeScreenLazyQuery>;
export type HomeScreenQueryResult = Apollo.QueryResult<HomeScreenQuery, HomeScreenQueryVariables>;
export const TerminsScreenDocument = gql`
    query terminsScreen($date: DateTimeISO!, $cursor: AppointmentWhereUniqueInput, $take: Int, $skip: Int) {
  futureAppointments: appointments(
    where: {start: {gt: $date}}
    orderBy: {start: asc}
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
  pastAppointments: appointments(
    where: {start: {lte: $date}}
    orderBy: {start: desc}
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
}
    `;

/**
 * __useTerminsScreenQuery__
 *
 * To run a query within a React component, call `useTerminsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminsScreenQuery({
 *   variables: {
 *      date: // value for 'date'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useTerminsScreenQuery(baseOptions: Apollo.QueryHookOptions<TerminsScreenQuery, TerminsScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminsScreenQuery, TerminsScreenQueryVariables>(TerminsScreenDocument, options);
      }
export function useTerminsScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminsScreenQuery, TerminsScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminsScreenQuery, TerminsScreenQueryVariables>(TerminsScreenDocument, options);
        }
export type TerminsScreenQueryHookResult = ReturnType<typeof useTerminsScreenQuery>;
export type TerminsScreenLazyQueryHookResult = ReturnType<typeof useTerminsScreenLazyQuery>;
export type TerminsScreenQueryResult = Apollo.QueryResult<TerminsScreenQuery, TerminsScreenQueryVariables>;