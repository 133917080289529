import { Stack, Typography, Box } from '@mui/material';
import { FC } from 'react';

export interface IStepperProps {
  steps: { label: string; value: string | number }[];
  activeStep: number;
}

export const Stepper: FC<IStepperProps> = (props) => {
  return (
    <Stack alignItems={'center'} gap={3}>
      <Stack direction={'row'} gap={5}>
        {props.steps.map((step, stepIndex) => (
          <Step key={step.value} label={step.value} active={props.activeStep === stepIndex} />
        ))}
      </Stack>
      <Typography variant="h5" fontWeight={'bold'}>
        {props.steps[props.activeStep]?.label}
      </Typography>
    </Stack>
  );
};

const Step = (props: { label: string | number; active: boolean }) => {
  return (
    <Box
      width={45}
      height={45}
      display={'flex'}
      borderRadius={'50%'}
      bgcolor={props.active ? 'primary.main' : 'info.main'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Typography
        variant="body1"
        fontWeight={'bold'}
        textAlign={'center'}
        color={props.active ? undefined : 'background.default'}
      >
        {props.label}
      </Typography>
    </Box>
  );
};
