import { ButtonProps, FormHelperText, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import OtpInput from 'react-otp-input';

import { TypographyOptions } from '@mui/material/styles/createTypography';
import { styled } from '@mui/system';
import { AppButton } from '../AppButton';

const StyledInput = styled('input')(
  ({ theme }) => `
  width: 3rem !important;
  height: 3rem;
  margin: 0;
  font-size: 1.4rem;
  border-radius: ${theme.spacing(1)};
  color: #000;
  border: 0;
  font-family: ${(theme.typography as TypographyOptions).fontFamily};
  font-weight: bold;

  &:focus {
    outline: none;
    border-color: red;
    border: 2px solid ${theme.palette.primary.main};
  }
`,
);

export interface IOtpCodeProps {
  onSubmit: (otp: string) => void;
  onResend: () => void;
  phone: string;
  buttonProps?: ButtonProps;
  error?: string;
  loading?: boolean;
}

export const OtpCode: FC<IOtpCodeProps> = (props) => {
  const [code, setCode] = useState('');

  const { onSubmit, onResend, phone } = props;

  const submit: ButtonProps['onClick'] = () => {
    onSubmit(code);
  };

  return (
    <Stack alignItems={'center'} justifyContent={'space-between'} flex={1} width={'100%'}>
      <Stack alignItems={'center'} mb={1} width={'100%'}>
        <div id="recaptcha-container"></div>

        <Stack>
          <OtpInput
            numInputs={6}
            value={code}
            onChange={setCode}
            renderInput={(props) => <StyledInput {...props} />}
            containerStyle={{
              gap: 5,
              width: '100%',
              justifyContent: 'space-between',
            }}
          />
          {props.error && (
            <FormHelperText
              sx={{
                color: '#E58A8A',
                marginX: 1.75,
                mt: 0,
              }}
            >
              {props.error}
            </FormHelperText>
          )}
        </Stack>

        <Stack alignItems={'center'} my={3}>
          <Typography variant="body1">Poslan je OTP kod na vaš broj</Typography>
          <Typography variant="body1">{phone}</Typography>
        </Stack>

        <Stack alignItems={'center'} mt={5}>
          <Typography variant="body1">Niste primili kod?</Typography>
          <Typography onClick={onResend} variant="body1" fontWeight={500} color="primary.main">
            Pošalji ponovno
          </Typography>
        </Stack>
      </Stack>

      <AppButton
        loading={props.loading}
        variant="contained"
        disabled={code.length !== 6}
        fullWidth
        onClick={submit}
        {...props.buttonProps}
      >
        verificiraj
      </AppButton>
    </Stack>
  );
};
