import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { ButtonProps, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';
import { FC } from 'react';
import { AppButton } from '../AppButton';
export const AppointmentSummary: FC<{
  start: Date;
  end: Date;
  serviceName: string;
  serviceDescription?: string | null | undefined;
  duration: number;
  price: number;
  barberFullName: string;
  button?: {
    text: string;
    props: ButtonProps;
  };
  disabled?: boolean;
  review?: boolean;
  icon?: typeof SvgIcon;
}> = ({ start, serviceName, serviceDescription, duration, price, barberFullName, button, disabled, review }) => {
  const theme = useTheme();

  return (
    <Stack
      padding={1.5}
      justifyContent={'center'}
      gap={1.5}
      alignItems={'center'}
      width="100%"
      sx={{ background: theme.palette.secondary.main }}
    >
      <Stack width={'100%'} gap={0.6}>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          padding={1.2}
          borderRadius={'20px'}
          gap={1.2}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <CalendarMonthOutlinedIcon sx={{ color: disabled ? 'action.disabled' : theme.palette.primary.main }} />
          <Typography variant="body1" fontWeight={'500'} color={disabled ? 'action.disabled' : undefined}>
            {format(new Date(start), `dd.MM.yyyy 'u' HH:mm`)}
          </Typography>
        </Stack>
        <Stack gap={0.625}>
          <Stack>
            <RightText disabled={disabled} review={review}>
              {serviceName}
            </RightText>
            {review && serviceDescription && <LeftText review>{serviceDescription}</LeftText>}
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <LeftText disabled={disabled}>Trajanje:</LeftText>
            <RightText disabled={disabled} review={review}>
              {duration} min
            </RightText>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <LeftText disabled={disabled}>Frizer:</LeftText>

            <RightText disabled={disabled} review={review}>
              {barberFullName}
            </RightText>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <LeftText disabled={disabled}>Cijena:</LeftText>

            <RightText disabled={disabled} review={review}>
              {price} €
            </RightText>
          </Stack>
        </Stack>
      </Stack>
      {!disabled && !review && button && (
        <AppButton
          {...button.props}
          sx={{
            width: '80%',
            ...button.props.sx,
          }}
        >
          {button.text}
        </AppButton>
      )}
    </Stack>
  );
};

const LeftText: FC<{
  disabled?: boolean;
  review?: boolean;
  children: React.ReactNode;
}> = ({ children, disabled, review }) => {
  const color = disabled ? 'action.disabled' : review ? 'info.main' : grey[500];

  return (
    <Typography variant="body2" fontWeight={'600'} color={color}>
      {children}
    </Typography>
  );
};

const RightText: FC<{
  disabled?: boolean;
  review?: boolean;
  children: React.ReactNode;
}> = ({ children, disabled, review }) => {
  const color = disabled ? 'action.disabled' : review ? 'primary.main' : undefined;

  return (
    <Typography variant="body1" fontWeight={'500'} color={color}>
      {children}
    </Typography>
  );
};
